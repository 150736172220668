import * as React from "react";

import { useDrop } from "react-dnd";
import RemoveIcon from '@mui/icons-material/Close';
import { Button, MenuItem, Select } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { styles } from "../styles";
import { NewPlaylistCard } from "./cards";
import { COLORS, DAYS } from "../constants";
import { ariaScheduleType } from "../types";
import { newZoneContext } from "../pages/newZone";
import { GlobalContext, NotificationContext } from "..";
import { DoubleKnobSlider } from "../support";
import { getBreakTime, getScheduleDuration, useAriaSchedule } from "./scheduleHooks";
import { saveAriaSchedules } from "../api/firebase";
import dayjs from "dayjs";
import { useAriaVibes } from "./vibesHooks";

function AriaSlot({...props}) {
    const {onDrop, children, style, className} = props;

    const [_, dropRef] = useDrop({
        accept: "playlist",
        drop: (item) => {
            onDrop(item);
        },
        collect: (monitor) => ({isOver: monitor.isOver()})
    })

    return (
        <div ref={dropRef} style={{...style}} className={`${className}`}>{children}</div>
    )
}

function AriaSchedule({...props}) {
    const spotify = props.spotify;
    const zone = React.useContext(newZoneContext);
    const global = React.useContext(GlobalContext);
    const requireConfirmLeave = props.requireConfirmLeave;
    const unRequireConfirmLeave = props.unRequireConfirmLeave;
    const notification = React.useContext(NotificationContext);
    const updateSchedulePlaylists = props.updateSchedulePlaylists;

    const [activeDay, setActiveDay] = React.useState(new Date().getDay());
    const [activeSchedule, setActiveSchedule] = React.useState<ariaScheduleType>();
    const {vibes} = useAriaVibes(zone?.zone, notification?.setNotification, global?.setLoading);
    const {schedules, updateSchedules, schedule, saved} = useAriaSchedule(vibes, zone?.zone, notification?.setNotification, global?.setLoading, props.debugInfo);

    React.useEffect(() => {
        if (schedules.initialized && (typeof activeDay === "number")) {
            setActiveSchedule(schedules.schedules[activeDay]);
        }

        if (schedules.update) requireConfirmLeave();
        if (schedules.save) {
            const savePlaylistsWrapper = async () => {
                if (zone?.zone.id) {
                    const response = await saveAriaSchedules(zone?.zone.id, schedules);
                    console.log("[newZone][AriaSchedule] (saved)", response)
                    if (response) {
                        saved();
                        updateSchedulePlaylists(extractSpotifyIds(schedules.schedules))
                        unRequireConfirmLeave();
                    }
                    else notification?.setNotification({value: "Failed to save schedule, try again.", notify: true});
                }
            }
            savePlaylistsWrapper()
        }
    }, [activeDay, schedules])

    return (
        <>
        {activeSchedule? 
        <div className="width-100 flex column align-center" style={{maxWidth: styles.maxSectionWidth}}>
            {/* Header */}
            <div className="flex justify-between align-center width-100">
                <Button 
                    variant="contained" 
                    sx={{...styles.button, margin: "0px 0px 0px 0px", width: 150, height: 40}} 
                    onClick={() => schedule(spotify, zone?.spotifyAPI)}
                >
                    Update
                </Button>
                <Select 
                    value={activeDay} 
                    style={{
                        ...styles.dropdown, 
                        width: 150,
                        margin: "10px 0px 10px 0"
                    }} 
                    onChange={(event) => setActiveDay(parseInt(event.target.value as string))}
                >
                    {DAYS.map((day, i) => <MenuItem value={i} key={`sd-${i}`}>{day.name}</MenuItem>)}
                </Select>
            </div>

            {/* Slider */}
            <div className="flex align-center width-100" style={{...props.slotsStyle}}>
                {activeSchedule.ariaSlots?.map((slot, i) => {
                    // const n = activeSchedule.ariaSlots.length;
                    const slotWidth = computeSlotWidthFromBreak(activeSchedule.breaks, i);
                    // console.log(`[AriaSchedule][${i}] (slotWidth) >>`, slotWidth);

                    return (
                        <AriaSlot
                            key={`s-${i}`}
                            style={{
                                ...styles.bucket, 
                                overflow: "hidden",
                                width: `${slotWidth}%`,
                                borderLeft: i === 0? `1px solid ${COLORS.BLACK}` : "none",
                                borderRight: i === 2? `1px solid ${COLORS.BLACK}` : "none",
                            }}
                            onDrop={(item: {cover: string, name: string, spotifyUri: string, sourceIndex?: number}) => {
                                if (i === item.sourceIndex) return;
                                updateSchedules(activeDay, {item: item, slotIndex: i, sourceIndex: item.sourceIndex, add: true})
                            }}
                        >
                            {slot?.playlists?.map((item, j) => {
                                return (
                                    <NewPlaylistCard
                                        draggable
                                        {...item}
                                        type="playlist"
                                        sourceIndex={i}
                                        key={`ss-${3*i + j}`}
                                        deleteIcon={RemoveIcon}
                                        onDelete={() => updateSchedules(activeDay, {item: item, slotIndex: i, remove: true})}
                                    />
                                )
                            })}
                        </AriaSlot>
                    )
                })}
            </div>
            <DoubleKnobSlider 
                value={activeSchedule?.breaks || [33, 67]} 
                sx={{...styles.scheduleSlider, width: "100%"}} 
                minDistance={100*30/getScheduleDuration(activeSchedule)}
                valueLabelFormat={(value: number) => getBreakTime(value, activeSchedule)}
                setValue={(breaks: number[]) => updateSchedules(activeDay, {breaks: breaks})}
            />

            {/* Time Pickers */}
            <div className="flex align-center justify-between width-100" style={{maxWidth: styles.maxSectionWidth}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                        label="Start"
                        sx={{width: 150, borderRadius: 0}}
                        value={dayjs(activeSchedule.ariaSlots[0].start || "09:00", ["HH:mm"])}
                        onChange={(value: any) => {
                            if (!validStartDate(activeSchedule, value.toDate())) {
                                notification?.setNotification({value: "Start time must be at least 30 minutes before start time.", notify: true});
                                return;
                            }
                            updateSchedules(activeDay, {start: value.format("HH:mm")})
                        }}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock
                        }}
                    />
                    <TimePicker 
                        label="End"
                        sx={{width: 150}}
                        value={dayjs(activeSchedule.ariaSlots.at(-1)?.end || "17:00", ["HH:mm"])}
                        onChange={(value: any) => {
                            console.log((value.toDate() as Date).getTime())
                            if (!validEndDate(activeSchedule, value.toDate())) {
                                notification?.setNotification({value: "End time must be at least 30 minutes after start time.", notify: true});
                                return;
                            }
                            updateSchedules(activeDay, {end: value.format("HH:mm")})
                        }}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock
                        }}
                    />
                </LocalizationProvider>
            </div>
        </div> 
        : <></>
        }
        </>
    )
}

export default AriaSchedule;

/*** Helpers ***/
/**
 * Computes the width should be in percent from two break point 
 * 
 * @param breaks percentages indicating at what percent there is a break
 * @param i the slot we are computing the size of
 * @returns the width of the slot as a percentage of the parent element
 */
export function computeSlotWidthFromBreak(breaks: number[], i: number) {
    const n = breaks.length;
    switch (i) {
        case 0: return breaks[0];
        case n: return 100 - breaks[n - 1];
        default: return breaks[i] - breaks[i - 1]
    }
}

function extractSpotifyIds(schedules: ariaScheduleType[]): string[] {
    const spotifyIds = [];
    
    for (const schedule of schedules) {
        for (const slot of schedule.ariaSlots) spotifyIds.push(slot.spotifyId)
    }

    return spotifyIds;
}

function sameDay(day1: Date, day2: Date): boolean {
    return (   
        (day1.getFullYear() === day2.getFullYear()) &&
        (day1.getMonth() === day2.getMonth()) &&
        (day1.getDate() === day2.getDate())
    )
}
/**
 * Checks if a start date is a valid end date for a schedule 
 * Schedule's end is defined as the end of the last slot
 * Assumption: schedule has at least one slot with an end time
 * Start date must be on the same day of same month of same year as schedule's end
 * 
 * @param schedule aria schedule with the end in the last slot
 * @param start the proposed start date to check if valid
 * @returns true if the start date is valid, else false
 */
function validStartDate(schedule: ariaScheduleType, start: Date): boolean {
    const now = new Date();
    if (!sameDay(now, start)) return false;

    const nowDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
    const nowDate = nowDateTime.split("T")[0];

    const end = new Date(nowDate + "T" + schedule.ariaSlots.at(-1)?.end);
    console.log("[ariaSchedule][validStartDate] (start, end, now)", start, end, now)
    return end.getTime() - 30*60000 >= start.getTime();
}

/**
 * Checks if an end date is a valid end date for a schedule 
 * Schedule's start is defined as the start of the first slot
 * Assumption: schedule has at least one slot with a start time
 * End date must be on the same day of same month of same year as schedule's start
 * 
 * @param schedule aria schedule with the start in the first slot
 * @param end the proposed end date to check if valid
 * @returns true if the end date is valid, else false
 */
function validEndDate(schedule: ariaScheduleType, end: Date): boolean {
    const now = new Date();
    if (!sameDay(now, end)) return false;

    const nowDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
    const nowDate = nowDateTime.split("T")[0];
    const start = new Date(nowDate + "T" + schedule.ariaSlots[0].start);

    console.log("[ariaSchedule][validStartDate] (start, end, now)", start, end, now)
    return end.getTime() >= start.getTime() + 30*60000;
}