import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, createTheme } from '@mui/material';

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';
import Login from './pages/login';
import Admin from './pages/admin';
import NewZone from './pages/newZone';
import Location from './pages/location';
import { apolloClient } from './api/clients';
import reportWebVitals from './reportWebVitals';
import { getDevOrDepUrl, useCustomState } from './utils';
import { AuthProvider, useAuth } from './context/newAuth';
// import { AppUserProvider, useAppUser } from './context/auth';
import { LoadingContextType, NotificationContextType, NotificationType, loadingType } from './types';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const NotificationContext = React.createContext<NotificationContextType>(null);
export const GlobalContext = React.createContext<LoadingContextType | null>(null);

function App({...props}) {
  // const appUser = useAppUser();
  // const auth = useAuth();
  const [loading, setLoading] = useCustomState<loadingType>({});
  const [notification, setNotification] = useCustomState<NotificationType>({});
  const theme = createTheme({
    typography: {
      fontFamily: 'Helios Extended'
    }
  })
  
  return (
    <GlobalContext.Provider value={{loading: loading, setLoading: setLoading}}>
      <NotificationContext.Provider value={{notification: notification, setNotification: setNotification}}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path={getDevOrDepUrl("aria") || "/"} element={<Login />} />
              <Route path={getDevOrDepUrl("admin") || "/admin"} element={<Admin />} />
              <Route path={getDevOrDepUrl("location") || "/location"} element={<Location />} />
              <Route path={getDevOrDepUrl("zone") || "/zone"} element={<NewZone />} />
              <Route path={`${getDevOrDepUrl("aria")}*` || "/*"} element={<Login />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </NotificationContext.Provider>
    </GlobalContext.Provider>
  )
}

root.render(
  // <React.StrictMode>
    <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <App />
        </AuthProvider>
    </ApolloProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
