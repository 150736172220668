import { gql } from "@apollo/client";

export const GET_NOW_PLAYING = gql`
subscription MySubscription($soundZone: ID = "") {
    nowPlayingUpdate(input: {soundZone: $soundZone}) {
        nowPlaying {
            playFrom {
                ... on Playlist {
                    id
                    name
                }
                ... on Schedule {
                    id
                    playlists {
                        id
                        name
                    }
                }
            }
            track {
                id
                name
                duration
                artists {
                    name
                }
                album {
                    image {
                        url
                    }
                    colors {
                        primary
                        accent
                    }
                }
            }
        }
    }
}
`
export const PLAYBACK_UPDATE = gql`
subscription MySubscription($soundZone: ID = "") {
    playbackUpdate(input: {soundZone: $soundZone}) {
        playback {
            state
            volume
        }
    }
}
`

export const ZONE_UPDATE = gql`
    subscription MySubscription($soundZone: ID = "") {
        soundZoneUpdate(input: {soundZone: $soundZone}) {
            soundZone {
                    isPaired
                    online
                    playback {
                        state
                    }
                    account {
                        settings {
                            filterExplicit
                        }
                    }
            }
        }
    }
`

export const SCHEDULE_UPDATE = gql`
    subscription MySubscription($schedule: ID = "") {
        scheduleUpdate(input: {schedule: $schedule}) {
            schedule {
                playlists {
                    name 
                    id
                }
                slots {
                    rrule
                    start
                    duration
                    playlistIds
                }
            }
        }
    }
`