import { API } from "../constants";
import { ariaVibeType, debugInfoType, spotifyTrackType } from "../types";
import { newPost } from "../utils";

export async function initiateLogging(body: any) {
    // const url = "https://fabhous.com/api/aria/playback/startLog"; // development url
    const url = "https://ariasound.live/api/playback/startLog"; 
    
    try {
        newPost(url, JSON.stringify(body), {'Content-Type': API.APPLICATION_JSON})
    } catch (e) {
        console.error("[aria][initiateLogging] >> Failed to initiate logger")
    }
}

export async function sortSlot(tracks: spotifyTrackType[], slotVibes: ariaVibeType, debugInfo: debugInfoType = {energyWeight: "0.5", moodWeight: "0.5"}) {
    // const url = "https://fabhous.com/api/aria/schedule/sortSlot"; // development url
    const url = "https://ariasound.live/api/schedule/sortSlot"; // development url
    
    return newPost(url, JSON.stringify({
        tracks: tracks,
        slotVibes: slotVibes,
        debugInfo: debugInfo
    }), {'Content-Type': API.APPLICATION_JSON})
}

export async function getPlaybackLog(start: number, end: number) {
    // const url = "https://fabhous.com/api/aria/playback/getLog"; // development url
    const url = "https://ariasound.live/api/playback/getLog"; // development url
    
    return newPost(url, JSON.stringify({
        start: start,
        end: end,
    }), {'Content-Type': API.APPLICATION_JSON})
}

export async function createAriaUser(email: string, password: string) {
    // const url = "https://fabhous.com/api/aria/playback/createAriaUser"; // development url
    const url = "https://ariasound.live/api/playback/createAriaUser"; // development url
    
    return newPost(url, JSON.stringify({
        email: email,
        password: password,
    }), {'Content-Type': API.APPLICATION_JSON})
}

export async function deleteAriaUser(uid: string) {
    // const url = "https://fabhous.com/api/aria/playback/deleteAriaUser"; // development url
    const url = "https://ariasound.live/api/playback/deleteAriaUser"; // development url
    
    return newPost(url, JSON.stringify({uid: uid}), {'Content-Type': API.APPLICATION_JSON})
}