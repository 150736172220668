import * as React from "react";


import { Col } from "react-bootstrap";
import MenuIcon from '@mui/icons-material/Menu';
import RemoveIcon from '@mui/icons-material/Close';
import { Button, IconButton, Modal, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import iconHome from '../assets/Icon_Home_Black.png';
import iconNetwork from '../assets/Icon_Network_Black.png';

import { styles } from "../styles";
import { COLORS, SECTIONS } from "../constants";
import { GlobalContext, NotificationContext } from "..";
import { Notification } from "../support";
import { NewPlaylistCard } from "./cards";
import { FormField } from "../forms/fields";
import { LoadSpotifyPlaylist } from "../api/syb";
import { newZoneContext } from "../pages/newZone";
import { newNowPlayingType, spotifyPlaylist, sybPlaylist } from "../types";
import { dynamicResizeCallbackWrapper, findPlaylist, loadSYBPlaylistData, resize, useCustomState, validateUrl } from "../utils";

function HeaderButton({...props}) {
    const zone = React.useContext(newZoneContext);

    const {name, onClick, setCollapse, icon, style } = props;
    return (
        <div className="width-100 flex align-center" style={{margin: "5px 0 0 0"}}>
            <IconButton 
                className="pointer" 
                onClick={dynamicResizeCallbackWrapper(onClick, setCollapse, zone?.width)}
                style={{...style}} 
            >
                <img src={icon} style={{color: COLORS.BLACK, width: '100%', height: '100%' }} alt=""/>   
            </IconButton>
            <Typography style={{margin: "0 0 0 10px", cursor: 'pointer'}}>{name}</Typography>
        </div>
    )
}

function Sidebar({...props}) {
    const {playlists, setPlaylists, onPlaylistCardClick, onHomeClick, onZonesClick, onDelete} = props;

    const syb = playlists?.sidebar?.syb;
    const spotify = playlists?.sidebar?.spotify;
    const zone = React.useContext(newZoneContext);
    const global = React.useContext(GlobalContext);
    const nowPlaying: newNowPlayingType = props.nowPlaying;
    const notification = React.useContext(NotificationContext);

    const [collapse, setCollapse] = React.useState(false);
    const [playlistUrl, setPlaylistUrl] = React.useState("");
    const [addPlaylist, setAddPlaylist] = React.useState(false);
    const [sybPlaylistToAdd, setSYBPlaylistToAdd] = useCustomState<sybPlaylist>(null);
    const [spotifyPlaylistToAdd, setSpotifyPlaylistToAdd] = useCustomState<spotifyPlaylist>(null);

    const loadSpotifyPlaylist = LoadSpotifyPlaylist();

    const clear = () => {
        setPlaylistUrl("");
        setSYBPlaylistToAdd(null);
        setSpotifyPlaylistToAdd(null);
    }

    const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const url = event.target.value;
        if (url) {
            setPlaylistUrl(url);
            if (validateUrl(url)) {
                let id: string;
                try {
                    id = url.split("/")[4].split("?")[0];
                } catch (error) {return}
                if (!id) return

                if (findPlaylist(id, undefined, undefined, spotify)) {
                    notification?.setNotification({value: "Playlist already loaded", notify: true}); return;
                }

                if (zone) {
                    global?.setLoading({load: true, message: "Loading playlist..."});
                    const spotifyPlaylist: spotifyPlaylist | undefined = await zone.spotifyAPI.loadPlaylist(id, false);
                    if (spotifyPlaylist && zone.zone) {
                        setSpotifyPlaylistToAdd(spotifyPlaylist);
                        
                        const sybPlaylist: sybPlaylist = await loadSYBPlaylistData(loadSpotifyPlaylist, zone.zone, spotifyPlaylist.tracks?.length || 0, undefined, `spotify:playlist:${id}`, false);
                        setSYBPlaylistToAdd(sybPlaylist);
                        notification?.setNotification({value: "Playlist loaded", notify: true});
                    }
                    global?.setLoading({load: false, message: ""});
                }
            }
        } else clear();
    }

    const savePlaylist = () => {
        if (!findPlaylist(sybPlaylistToAdd?.id, undefined, undefined, undefined, syb)) {
            setPlaylists({
                sidebar: {
                    syb: [...syb, sybPlaylistToAdd],
                    spotify: [...spotify, spotifyPlaylistToAdd]
                },
                update: true
            });

            clear();
            setAddPlaylist(false);
        }
    }

    return (
        <>
        {!collapse || resize(zone?.width, zone?.orientation)? 
            <Col className={"fixed"} style={{...styles.sidebar, width: resize(zone?.width, zone?.orientation)? 225 : "100%"}}>
            <div className="flex justify-between" style={{...styles.sidebarHomeCont}}>
                <div className="flex justify-between column">
                    <HeaderButton 
                        zone={zone} 
                        name={"Home"} 
                        icon={iconHome} 
                        onClick={onHomeClick} 
                        setCollapse={setCollapse}
                        style={styles.sidebarHomeButton}
                    />
                    <HeaderButton 
                        zone={zone} 
                        name={"Zones"} 
                        icon={iconNetwork} 
                        onClick={onZonesClick} 
                        setCollapse={setCollapse}
                        style={styles.sidebarHomeButton}
                    />
                </div>
                {resize(zone?.width, zone?.orientation)? 
                    <></> : 
                    <KeyboardDoubleArrowLeftIcon 
                        onClick={() => setCollapse(true)}
                        className="pointer" style={{width: 30, height: 30}} 
                    />
                }
            </div>


            <div style={{height: "calc(100% - 141px)", overflow: "scroll", scrollbarWidth: "none"}}>
                <Typography fontSize={22} style={{margin: "20px 20px 0 20px"}}>Playlists</Typography>
                <Button className="width-100" sx={{...styles.sidebarButton}} onClick={() => setAddPlaylist(true)}>
                    <div style={{...styles.sidebarBox}} className="flex align-center justify-center">+</div> Add Playlist 
                </Button>

                {spotify?.map((playlist: spotifyPlaylist, i: number) => {
                    const playlistIsPlaying: boolean = syb[i]? syb[i].name.trim() === nowPlaying?.playFrom?.playlist?.name?.trim() : false;
                    
                    return (
                        <NewPlaylistCard 
                            type="playlist" 
                            // sybId={syb[i].id}
                            name={playlist.name}
                            cover={playlist.cover}
                            spotifyUri={playlist.uri}
                            draggable={zone?.zone.selectedSection === SECTIONS.SCHEDULE}
                            onClick={dynamicResizeCallbackWrapper(onPlaylistCardClick, setCollapse, zone?.width)}

                            deleteIcon={RemoveIcon}
                            key={`loaded-playlist-${i}`}
                            onDelete={(event: React.MouseEvent<HTMLElement>) => {onDelete(spotify, playlist.uri); event.stopPropagation()}}
                            style={{
                                justifyContent: "space-between", 
                                color: playlistIsPlaying? COLORS.GRAY : COLORS.BLACK,
                                backgroundColor: playlistIsPlaying? COLORS.ORANGE : COLORS.GRAY
                            }}
                        />
                    )
                }
                )}
            </div>

            <Modal open={addPlaylist} onClose={() => setAddPlaylist(false)}>
                <div className="absolute flex align-center column justify-between" style={{...styles.addPlaylistModal}}>
                    <Typography fontSize={22}>Add Playlist</Typography>
                    <div className="text-center">
                        {spotifyPlaylistToAdd? 
                            <>
                                <img src={spotifyPlaylistToAdd.cover} style={{...styles.modalImage}} alt=""/> 
                                <Typography fontSize={18}>{spotifyPlaylistToAdd.name}</Typography>
                            </>
                            : <div style={{...styles.modalImage}}></div>
                        }
                    </div>
                    <div className="text-center">
                        <FormField 
                            variant="outlined" 
                            value={playlistUrl || ""}
                            onChange={handleOnChange}
                            onTouchEnd={handleOnChange}
                            style={{...styles.formField}} 
                            placeholder="Paste spotify playlist url"
                        />
                        <Button sx={{...styles.button}} onClick={savePlaylist}>Add</Button>
                    </div>
                </div>
            </Modal>
            <Notification notification={notification?.notification} setNotification={notification?.setNotification} />
            </Col>
            : 
            <Button 
                className="fixed"
                onClick={() => setCollapse(false)} 
                style={{...styles.sidebarOpenButton}} 
            >
                <MenuIcon style={{width: 30, height: 30}}/>
            </Button>
        }
        </>
    )
}

export default Sidebar;
