import * as React from "react";

import { useDrag } from 'react-dnd';
import { Button, Typography } from "@mui/material";

import { styles } from "../styles";
import { useAppUser } from "../context/auth";

export function NewPlaylistCard({...props}) {
    const appUser = useAppUser();
    const {type, spotifyUri, name, cover, draggable, sourceIndex, onClick} = props;
    
    const [_, dragRef] = useDrag({
        type: type,
        item: {spotifyUri: spotifyUri, name: name, cover: cover, sourceIndex: sourceIndex},
        collect: (monitor) => ({isDragging: monitor.isDragging()})
    })
    
    return (
        <Button 
            ref={draggable? dragRef : null}
            onClick={() => onClick? onClick(spotifyUri) : undefined}
            className="width-100 playlist-card flex align-center" 
            sx={{...styles.sidebarButton, ...props.style, justifyContent: "space-between"}} 
        >
            <div className="flex align-center text-overflow-ellipsis overflow-auto">
                <img src={cover} style={{...styles.sidebarBox}} alt=""/> 
                <Typography className="text-overflow-ellipsis overflow-auto">{name}</Typography>
            </div>
            {appUser?.clearance && props.onDelete? 
                <props.deleteIcon 
                    color={"error"} 
                    fontSize="small" 
                    style={{marginLeft: 5}}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        props.onDelete(event);
                        event.stopPropagation();
                    }} 
                    className="playlist-card-delete appear-on-hover"
            />
                : <></>
            }
        </Button>
    )
}
