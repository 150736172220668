import * as React from "react";

import { DndProvider } from "react-dnd";
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Typography } from "@mui/material";

import { Container } from "react-bootstrap";
import { useSubscription } from "@apollo/client";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useLocation, useNavigate } from "react-router-dom";


import NewPlaylist from "../components/newPlaylist";
import iconVibe from '../assets/Icon_Vibes_Black.png';
import iconClock from '../assets/Icon_Clock_Black.png';
import iconMusicNote from '../assets/Icon_MusicNote_Black.png';

import { styles } from "../styles";
import Nav from "../components/nav";
import { User } from "../classes/user";
import Sidebar from "../components/sidebar";
import { Spotify } from "../api/newSpotify";
import { useAuth } from "../context/newAuth";
import Settings from "../components/settings";
import Controls from "../components/controls";
import { initiateLogging } from "../api/aria";
import AriaVibes from "../components/newVibes";
import { COLORS, SECTIONS } from "../constants";
import AriaSchedule from "../components/ariaSchedule";
import { GlobalContext, NotificationContext } from "..";
import { getSoundZoneMetadata, savePlaylists } from "../api/firebase";
import { Confirm, Loading, Notification, useScreenOrientation, useViewport } from "../support";
import { GET_NOW_PLAYING, PLAYBACK_UPDATE, SCHEDULE_UPDATE, ZONE_UPDATE } from "../subscriptions";
import { ClearQueueTracks, FetchNowPlaying, FetchSYBPlaylist, FetchZoneStatus, GetScheduledPlaylist, LoadSpotifyPlaylist, QueueTracks } from "../api/syb";
import { confirmType, debugInfoType, newNowPlayingType, newPlayFromType, newPlaybackType, newPlaylistsType, newZoneType, spotifyPlaylist, sybPlaylist, sybScheduleType } from "../types";
import { addToLocalStorage, extractPlayFrom, findNowPlayingTrack, findPlaylist, findScheduledPlaylist, initializeSchedulePlaylists, initializeSidebarPlaylists, loadSYBPlaylistData, resize, updateSidebarPlaylist, useCustomState, findScheduledSlot, getNextTracks, clearCookies, getDevOrDepUrl } from "../utils";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const Backend = isMobile ? TouchBackend : HTML5Backend; 

const spotifyAPI = new Spotify();
export const newZoneContext = React.createContext<{zone: newZoneType, spotifyAPI: Spotify, width: number, orientation: string} | null>(null);

function NewZone({...props}) {
    const auth = useAuth();
    const navigate = useNavigate();
    const state = useLocation().state;
    const global = React.useContext(GlobalContext);
    const notification = React.useContext(NotificationContext);

    const {width} = useViewport();
    const orientation = useScreenOrientation();

    const [zone, setZone] = useCustomState<newZoneType>({
        id: state.zone.id,
        name: state.zone.name,
        scheduleId: state.zone.scheduleId,
        selectedLocation: state.selectedLocation
    });

    const queueTracks = QueueTracks();
    const getPlaylist = FetchSYBPlaylist();
    const getNowPlaying = FetchNowPlaying();
    const getZoneStatus = FetchZoneStatus();
    const clearQueueTracks = ClearQueueTracks();
    const loadSpotifyPlaylist = LoadSpotifyPlaylist();
    const getScheduledPlaylist = GetScheduledPlaylist();
    const zoneSubscription = useSubscription(ZONE_UPDATE, {variables: {soundZone: zone.id}});
    const playbackSubscription = useSubscription(PLAYBACK_UPDATE, {variables: {soundZone: zone.id}});
    const sybScheduleSubscription = useSubscription(SCHEDULE_UPDATE, {variables: {schedule: zone.scheduleId}});
    const nowPlayingSubscription = useSubscription(GET_NOW_PLAYING, {variables: {soundZone: zone.id}});

    const [confirm, setConfirm] = useCustomState<confirmType>({});
    const [playback, setPlayback] = useCustomState<newPlaybackType>({});
    const [playlists, setPlaylists] = useCustomState<newPlaylistsType>({});
    const [sybSchedule, setSYBSchedule] = useCustomState<sybScheduleType>({});
    const [nowPlaying, setNowPlaying] = useCustomState<newNowPlayingType>({});
    const [debugInfo, setDebugInfo] = useCustomState<debugInfoType>({
        energyWeight: 0.5,
        moodWeight: 0.5
    });

    const updateSidebarPlaylistWrapper = async (uri: string) => {
        const syb: sybPlaylist[] = playlists.sidebar.syb;
        const spotify: spotifyPlaylist[] = playlists.sidebar.spotify; 

        if (global) {
            const update = localStorage.getItem(uri)? false : true;
            const sidebar = await updateSidebarPlaylist(uri, zone, syb, spotifyAPI, global, spotify, notification, loadSpotifyPlaylist);
            if (sidebar) {
                setPlaylists({
                    update: update,
                    sidebar: sidebar
                })
            }
        }
    }

    const updateSchedulePlaylist = async (id: string, name: string) => {
        const spotifyPlaylist = findPlaylist(undefined, undefined, name, playlists.schedule?.spotify);
        const sybPlaylist = await loadSYBPlaylistData(getPlaylist, zone, spotifyPlaylist?.tracks?.length || 1000, id);

        setPlaylists({
            schedule: {
                spotify: playlists.schedule?.spotify,
                syb: playlists.schedule?.syb?.map((playlist, i) => playlists.schedule.spotify[i].name === name? sybPlaylist : playlist),
            }
        });
    }

    const updateNowPlaying = async (response: any) => {
        let syb: sybPlaylist[] | undefined;
        let updatePlaylist: CallableFunction | undefined;
        if (!response.playFrom) {
            notification?.setNotification({value: "Nothing is currently playing.", notify: true});
            return
        }

        switch(response.playFrom.__typename) {
            case "Playlist": 
                syb = playlists.sidebar.syb;
                updatePlaylist = updateSidebarPlaylistWrapper; 
                break;
            case "Schedule": 
                syb = playlists.schedule?.syb;
                updatePlaylist = updateSchedulePlaylist; 
                break;
            default: break;
        }

        if (updatePlaylist && syb) {
            let scheduleQueued = false;
            const playFrom: newPlayFromType = await extractPlayFrom(response, zone, updatePlaylist, playlists.sidebar?.spotify, playlists.schedule?.spotify, getScheduledPlaylist);
            const i = findNowPlayingTrack(playlists, playFrom, response.track.name, "next", "syb") as number || 0;
            // const spotifyTrack = findNowPlayingTrack(playlists, playFrom, response.track.name, undefined, "spotify") as spotifyTrackType;

            if (i >= 0 && playFrom.schedule && (!nowPlaying.scheduleQueued || playFrom.schedule?.playlist.id !== nowPlaying.playFrom.schedule?.playlist.id)) {
                console.log("[newZone] (i) >>", i);
                const scheduledPlaylist = playlists.schedule.syb.find(sybPlaylist => sybPlaylist?.id === playFrom.schedule?.playlist.id);
            
                const scheduledSlot = findScheduledSlot(sybSchedule);
                const nextTracks = getNextTracks(i, scheduledPlaylist, scheduledSlot);
            
                console.log("[newZone] (nextTrackId) >>", nextTracks);
                const queueStatus = await queueTracks({variables: {soundZone: zone.id, tracks: nextTracks}});
                console.log("[newZone] (queueStatus) >>", queueStatus);
                scheduleQueued = true;
            } else if (playFrom.playlist && nowPlaying.scheduleQueued) {
                const queueStatus = await clearQueueTracks({variables: {soundZone: zone.id}});
                console.log("[newZone] (queueStatus) >>", queueStatus);
            }

            setNowPlaying({
                scheduleQueued: scheduleQueued,

                playFrom: playFrom? playFrom : nowPlaying.playFrom,
                track: {
                    name: response.track.name,
                    artists: response.track.artists,
                    duration: response.track.duration,
                    color: response.track.album?.colors,
                    cover: response.track.album?.image?.url,
                }
            })
        }
    }

    const notifyZoneStatus = (zoneStatus: any) => {
        if (!zoneStatus.online) notification?.setNotification({value: `${zone.name} is offline`, notify: true});
        else if ((typeof zone.online === "boolean") && !zone.online) {
            notification?.setNotification({value: `${zone.name} is online!`, notify: true});
        }
        
        if (!zoneStatus.isPaired) notification?.setNotification({value: `${zone.name} is not paired`, notify: true});
        else if ((typeof zone.isPaired === "boolean") && !zone.isPaired) {
            notification?.setNotification({value: `${zone.name} is paired!`, notify: true});
        }
        
        console.log("[notifyZoneStatus][zone] (online) >>", zoneStatus.online);
        console.log("[notifyZoneStatus][zone] (isPaired) >>", zoneStatus.isPaired);
    }

    const cancelConfirm = () => {
        if (confirm.callback) confirm.callback();

        setConfirm({
            required: false, 
            editing: undefined,
            callback: undefined,
            openDialogue: false,
        })
    }
    const confirmRequired = (callback?: CallableFunction, required?: boolean, title?: string, content?: string) => {
        if (confirm.required || required) {
            setConfirm({
                openDialogue: true, 
                callback: callback,
                title: title,
                content: content
            });
        } else if (callback) callback();
    }

    window.onbeforeunload = () => confirm.required? `Are you sure you want to leave? Your changes to ${confirm.editing} will be lost` : window.location.reload();
    
    /*** Zone State Management ***/
    React.useEffect(() => {
        console.log("[newZone] (user) >>", auth?.user)
        if (auth?.user instanceof User) {
            const initializeAppUserWrapper = async () => {
                // TODO: begin logging on enter
                const currentLocationId = localStorage.getItem("currentLocationId");
                const initiateLoggingResponse = await initiateLogging({
                    userEmail: auth.user?.obj?.email,
                    userUID: auth.id,
                    
                    zoneId: zone.id,
                    zoneName: zone.name,
                    account: auth.user?.obj?.sybAccount,
                    locationId: currentLocationId? currentLocationId : null,
                })
                console.log("[newZone][appUser] (initiateLoggingResponse) >>", initiateLoggingResponse)
            }
            initializeAppUserWrapper();
        }
    }, [auth?.user])

    React.useEffect(() => {
        if (!state) {
            const url = getDevOrDepUrl("aria");
            if (url) navigate(url);
            else return;
        }

        if (auth?.user && !zone.initialized) {
            clearCookies();

            const initializeZoneWrapper = async () => {
                const response = (await getZoneStatus({id: zone.id}))
                if (!response) return;

                const zoneStatus = response.data.soundZone;
                notifyZoneStatus(zoneStatus);
                console.log("[newZone] (filterExplicit) >>", zoneStatus.account.settings.filterExplicit)

                const zoneMetaData = (await getSoundZoneMetadata(zone.id));
                setZone({
                    initialized: true,
                    metaData: zoneMetaData,
                    online: zoneStatus.online,
                    isPaired: zoneStatus.isPaired,
                    clearance: auth.user?.obj?.clearance,
                    filterExplicit: zoneStatus.account.settings.filterExplicit,
                    vibesUpdated: [false, false, false, false, false, false, false],
                });
                setPlayback({
                    state: zoneStatus.device?.playback?.state, 
                    volume: zoneStatus.device?.playback?.volume
                });
            } 
            initializeZoneWrapper();
        } else if (
            zoneSubscription.data &&
            (
                zoneSubscription.data.soundZoneUpdate.soundZone.online !== zone.online ||
                zoneSubscription.data.soundZoneUpdate.soundZone.isPaired !== zone.isPaired
            )
        ) {
            const filterExplicit = zoneSubscription.data.soundZoneUpdate.soundZone.account.settings.filterExplicit;
            if (filterExplicit !== zone.filterExplicit) console.log("[newZone] (filterExplicit) >>", filterExplicit)

            setZone({
                filterExplicit: filterExplicit,
                online: zoneSubscription.data.soundZoneUpdate.soundZone.online,
                isPaired: zoneSubscription.data.soundZoneUpdate.soundZone.isPaired,
            })
            notifyZoneStatus(zoneSubscription.data.soundZoneUpdate.soundZone)
        } else if (zone.reinitialize) {
            const reinitializeZoneWrapper = async () => {
                const zoneMetaData = (await getSoundZoneMetadata(zone.id));
                setZone({
                    reinitialize: false,
                    metaData: zoneMetaData
                });
            }
            reinitializeZoneWrapper()
        }
    }, [zone, zoneSubscription.data, auth?.user])

    /*** SYB Schedule State Management ***/
    React.useEffect(() => {
        if (!sybSchedule.initialized) {
            const initializeSYBScheduleWrapper = async () => {
                setSYBSchedule({initialized: true});
                const sybSchedule = (await getScheduledPlaylist({id: zone.scheduleId})).data.schedule;
                console.log("[newZone][sybSchedule] (sybSchedule) >>", sybSchedule);
                setSYBSchedule({
                    slots: sybSchedule.slots,
                    playlists: sybSchedule.playlists
                })
            }
            initializeSYBScheduleWrapper()
        } else if (
            sybScheduleSubscription.data && 
            sybScheduleSubscription.data.scheduleUpdate.schedule &&
            JSON.stringify(sybScheduleSubscription.data.scheduleUpdate.schedule) !== localStorage.getItem("sybSchedule")
        
        ) {
            console.log("[newZone][sybSchedule] (sybSchedule) >>", sybScheduleSubscription.data.scheduleUpdate.schedule);
            addToLocalStorage("sybSchedule", JSON.stringify(sybScheduleSubscription.data.scheduleUpdate.schedule || ""));

            setSYBSchedule({
                slots: sybScheduleSubscription.data.scheduleUpdate.schedule.slots,
                playlists: sybScheduleSubscription.data.scheduleUpdate.schedule.playlists
            });

            // notification?.setNotification({value: "Schedule has updated. If it was not you, please refresh.", notify: true})
            // window.location.reload();
            setPlaylists({reinitialize: true});
            setZone({reinitialize: true});
        } 
    }, [sybSchedule, sybScheduleSubscription])

    /*** Playlists State Management ***/
    React.useEffect(() => {
        if (zone.initialized && !zone.reinitialize && (!playlists.initialized || playlists.reinitialize)) {
            setPlaylists({initialized: true, reinitialize: false});

            const initializePlaylistsWrapper = async () => {
                console.log("[newZone][playlists] (zoneMetaData) >>", zone.metaData);
                
                setPlaylists({sidebar: initializeSidebarPlaylists(zone.metaData)});

                global?.setLoading({load: true, message: "AI Playlist loading, please wait a minute..."});
                setPlaylists({schedule: await initializeSchedulePlaylists(zone.metaData, spotifyAPI), update: playlists.reinitialize});
                global?.setLoading({message: "", load: false});

                notification?.setNotification({value: "AI Playlists loaded!", notify: true});
            }
            initializePlaylistsWrapper();
        } else if (playlists.update) {
            if (!playlists.schedule) return;
            
            setPlaylists({update: false});
            if (nowPlaying.track) {
                const track = findNowPlayingTrack(playlists, nowPlaying.playFrom, nowPlaying.track.name, undefined, "syb");
                if (track) setNowPlaying({track: track});
            }
            // save zone playlist info to firebase
            const savePlaylistsWrapper = async () => {
                const response = await savePlaylists(zone.id, playlists);
                console.log("[newZone][playlists] (saved)", response)
            }
            savePlaylistsWrapper()
        }
    }, [zone, playlists])

    /*** Playback State Management ***/
    React.useEffect(() => {
        if (!playback.initialized) {
            setPlayback({initialized: true});
        } else if (
            playbackSubscription.data && 
            playbackSubscription.data.playbackUpdate.playback.state !== playback.state
        ) {
            const {state, volume} = playbackSubscription.data.playbackUpdate.playback;
            console.log(`[newZone][playback] (state, volume) >>`, state, volume)
            setPlayback({
                state: state,
                volume: volume
            })
        } 
    }, [playback, playbackSubscription.data])

    /*** NowPlaying State Management ***/
    React.useEffect(() => {
        if (!playlists.sidebar || !playlists.schedule) return;
        if (!nowPlaying.initialized) {
            setNowPlaying({initialized: true})
            const initializeNowPlayingWrapper = async () => {
                const nowPlaying = (await getNowPlaying({id: zone.id})).data.nowPlaying;
                console.log("[newZone][nowPlaying] (nowPlaying) >>", nowPlaying);
                
                updateNowPlaying(nowPlaying);
            }
            initializeNowPlayingWrapper();
        } else if (
                nowPlayingSubscription.data && 
                nowPlayingSubscription.data.nowPlayingUpdate?.nowPlaying &&
                JSON.stringify(nowPlayingSubscription.data.nowPlayingUpdate?.nowPlaying) !== localStorage.getItem("nowPlaying")
        ) {
            console.log(`[newZone][nowPlaying] (nowPlaying) >>`, nowPlayingSubscription.data.nowPlayingUpdate.nowPlaying)

            addToLocalStorage("nowPlaying", JSON.stringify(nowPlayingSubscription.data.nowPlayingUpdate.nowPlaying || ""));
            updateNowPlaying(nowPlayingSubscription.data.nowPlayingUpdate.nowPlaying);
        } else {
            global?.setLoading({load: false, message: null});
        }
    }, [nowPlaying, playlists, nowPlayingSubscription.data]);

    /*** Dynamic Resizing ***/
    React.useEffect(() => {
        if (
            (
                zone.selectedSection === SECTIONS.SCHEDULE || 
                zone.selectedSection === SECTIONS.VIBES
            ) && !resize(width, orientation)
        ) {
            setZone({selectedSection: undefined})
        }
    }, [width, orientation]);

    /*** Debug Management ***/
    React.useEffect(() => {
        if (zone.vibesUpdated) {
            setZone({
                vibesUpdated: zone.vibesUpdated.map((value, i) => i === new Date().getDay()? true : value)
            })
        }
    }, [debugInfo]);

    React.useEffect(() => {
        if (!zone.initialized) {
            console.log("[newZone] >> Initializing zone...");
            global?.setLoading({load: true, message: "Initializing zone..."});
        } else if (!sybSchedule.initialized) {
            console.log("[newZone] >> Initializing sybSchedule...");
            global?.setLoading({load: true, message: "Initializing sybSchedule..."});
        } else if (!playlists.initialized) {
            console.log("[newZone] >> Initializing playlists...");
            global?.setLoading({load: true, message: "Initializing playlists..."});
        } else if (!nowPlaying.initialized) {
            console.log("[newZone] >> Initializing nowPlaying...");
            global?.setLoading({load: true, message: "Initializing nowPlaying..."});
        } else {
            global?.setLoading({load: false, message: null});
        }
    }, [zone, sybSchedule, playlists, nowPlaying])

    return (
        <newZoneContext.Provider value={{zone: zone, spotifyAPI: spotifyAPI, width: width, orientation: orientation}}>
            <Container 
                fluid 
                className="column width-100 flex height-100 align-center" 
                style={{
                    margin: `${resize(width, orientation)? 35.5 : 0}px 0 0 0`
                }}
            >
                <DndProvider backend={Backend}>
                    {/* {auth?.user?.obj?.clearance === 1?  */}
                    <Sidebar 
                        playlists={playlists} 
                        nowPlaying={nowPlaying}
                        setPlaylists={setPlaylists}
                        onPlaylistCardClick={async (uri: string) => {
                            confirmRequired(async () => {
                                await updateSidebarPlaylistWrapper(uri);
                                setZone({
                                    selectedPlaylistUri: uri,
                                    // selectedPlaylistId: sybId,
                                    selectedPlaylistType: "sidebar",
                                    selectedSection: SECTIONS.PLAYLIST
                                })
                            })
                        }}
                        onDelete={(spotify: spotifyPlaylist[], uri: string) => {
                            confirmRequired(() => {
                                const syb = playlists.sidebar.syb;
                                localStorage.removeItem(uri);
                                const sybId = syb.find((_, i) => spotify[i].uri === uri)?.id;
                                if (sybId) localStorage.removeItem(sybId);
                                
                                setPlaylists({
                                    update: true,
                                    sidebar: {
                                        spotify: spotify.filter(playlist => playlist.uri !== uri),
                                        syb: syb.filter((_, i) => spotify[i].uri !== uri)
                                    }
                                })
                                if (zone.selectedPlaylistUri === uri) setZone({selectedSection: undefined});
                            }, true, "Are you sure you want to delete playlist", "This action can not be undone");
                        }}
                        onZonesClick={() => confirmRequired(() => {
                            const url = getDevOrDepUrl("location")
                            if (url) navigate(url)
                            return;
                        })}
                        onHomeClick={() => confirmRequired(() => setZone({selectedSection: undefined}))}
                    /> 
                    {/* : <></>} */}

                    <div className="flex align-center column justify-center" style={{...styles.zoneBody(width, zone.selectedSection === SECTIONS.PLAYLIST, orientation)}} >
                        <div className={`flex align-center ${resize(width, orientation)? "justify-between" : "column"}`} style={{width: `${resize(width, orientation)? "90%" : "100%"}`}}>
                            <Typography fontSize={28} color={COLORS.BLACK}>{zone.name}</Typography>
                            <Nav 
                                style={{top: 0}}
                                icon={zone.selectedSection === SECTIONS.SETTINGS? ClearIcon : PersonIcon}
                                onClick={() => confirmRequired(() => {
                                        zone.selectedSection === SECTIONS.SETTINGS? 
                                            setZone({selectedSection: undefined}) : 
                                            setZone({selectedSection: SECTIONS.SETTINGS})
                                    })
                                    
                                }
                            />
                        </div>
                        <div className="flex justify-center align-center" style={{height: `calc(100vh - ${resize(width, orientation)? 227 : 240}px)`, width: "90%"}}>
                        {!zone.selectedSection? 
                            <div className={`flex flex-wrap justify-around width-100`} style={{maxWidth: styles.maxSectionWidth}}>
                                <div className="flex column align-center justify-center">
                                    <IconButton 
                                        style={{...styles.zoneSectionButton}} 
                                        onClick={async () => {
                                            if (!sybSchedule.initialized || !playlists.schedule) return;
                                            const id = findScheduledPlaylist(sybSchedule);
                                            if (id) {
                                                global?.setLoading({load: true, message: "Loading schedule..."});
                                                const scheduledPlaylist = sybSchedule.playlists.find((playlist: any) => playlist.id === id);

                                                if (scheduledPlaylist) {
                                                    await updateSchedulePlaylist(id, scheduledPlaylist.name);
                                                    global?.setLoading({load: false, message: ""});
                                                    
                                                    const spotifyPlaylist = findPlaylist(undefined, undefined, scheduledPlaylist.name, playlists.schedule?.spotify) as spotifyPlaylist; 
                                                    if (spotifyPlaylist) {
                                                        setZone({
                                                            selectedPlaylistId: id,
                                                            selectedPlaylistType: "schedule",
                                                            selectedSection: SECTIONS.PLAYLIST,
                                                            selectedPlaylistUri: spotifyPlaylist?.uri
                                                        })
                                                    } else notification?.setNotification({notify: true, value: "AI Playlist could not load"});
                                                } else notification?.setNotification({value: "Error loading scheduled playlist.", notify: true});
                                            } else notification?.setNotification({value: "Nothing is scheduled for right now.", notify: true});
                                        }}
                                    >
                                        <img src={iconMusicNote} style={{ width: '100%', height: '100%' }} alt=""/>
                                    </IconButton>
                                    <Typography fontSize={22}>AI Playlist</Typography>
                                </div>
                                {zone.clearance !== 1 || !resize(width, orientation)? <></> : 
                                    <>
                                    <div className="flex column align-center justify-center">
                                        <IconButton style={{...styles.zoneSectionButton}} onClick={() => setZone({selectedSection: SECTIONS.VIBES})}><img src={iconVibe} style={{ width: '100%', height: '100%' }} alt=""/></IconButton>
                                        <Typography fontSize={22}>Vibes</Typography>
                                    </div>
                                    <div className="flex column align-center justify-center">
                                        <IconButton style={{...styles.zoneSectionButton}} onClick={() => setZone({selectedSection: SECTIONS.SCHEDULE})}><img src={iconClock} style={{ width: '100%', height: '100%' }} alt=""/></IconButton>
                                        <Typography fontSize={22}>Schedule</Typography>
                                    </div>
                                    </>
                                }
                            </div>
                            : <></>
                        } 

                        {zone.selectedSection === SECTIONS.PLAYLIST? 
                            <NewPlaylist 
                                playback={playback}
                                debugInfo={debugInfo}
                                nowPlaying={nowPlaying}
                                setDebugInfo={setDebugInfo}
                                style={{maxWidth: styles.maxSectionWidth}}
                                scheduledSlot={findScheduledSlot(sybSchedule)}
                                headerStyle={{maxWidth: styles.maxSectionWidth}}
                                setScheduleQueued={() => setNowPlaying({scheduleQueued: true})}
                                spotifyPlaylist={findPlaylist(undefined, zone.selectedPlaylistUri, undefined, zone.selectedPlaylistType === "sidebar"? playlists.sidebar.spotify : playlists.schedule.spotify)}
                                sybPlaylist={findPlaylist(undefined, zone.selectedPlaylistUri, undefined, zone.selectedPlaylistType === "sidebar"? playlists.sidebar.spotify : playlists.schedule.spotify, zone.selectedPlaylistType === "sidebar"? playlists.sidebar.syb : playlists.schedule.syb)}
                            /> 
                            : <></>
                        }
                        
                        {zone.selectedSection === SECTIONS.SCHEDULE?
                            <AriaSchedule 
                                debugInfo={debugInfo}
                                spotify={playlists.sidebar.spotify}
                                slotsStyle={{maxWidth: styles.maxSectionWidth}}
                                updateSchedulePlaylists={async (spotifyIds: string[]) => {}}
                                unRequireConfirmLeave={() => {
                                    setConfirm({required: false, editing: undefined});
                                    setZone({
                                        vibesUpdated: [false, false, false, false, false, false]
                                    })
                                }}
                                requireConfirmLeave={() => setConfirm({required: true, editing: SECTIONS.SCHEDULE})}
                            /> 
                            : <></>
                        }

                        {zone.selectedSection === SECTIONS.VIBES?
                            <AriaVibes flagVibesUpdate={(activeDay: number) => setZone({vibesUpdated: zone.vibesUpdated.map((value, i) => i === activeDay? true : value)})}/> 
                            : <></>
                        }   

                        {zone.selectedSection === SECTIONS.SETTINGS? 
                            <Container className="flex column align-center width-100" style={{margin: 0}}>
                                <Settings location={zone.selectedLocation}/> 
                            </Container>
                            : <></>
                        }
                        </div>
                    </div>
                    <Controls playback={playback} nowPlaying={nowPlaying} playlists={playlists} />
                </DndProvider>
            </Container>
            
            <Confirm 
                disagreeText="Cancel"
                agreeAutoFocus={false}
                handleDisagree={() => setConfirm({openDialogue: false})}
                title={confirm.title? confirm.title : "Are you sure you want to go back?"}
                content={confirm.content? <>{confirm.content}</> : <>Your changes to {confirm.editing} <b>will not be saved</b></>}
                agreeText={<Typography style={{color: COLORS.ORANGE, fontSize: "0.875rem"}}>Confirm</Typography>}
                handleAgree={() => cancelConfirm()}
                
                open={confirm.openDialogue || false} 
                style={{
                    borderRadius: 0,
                    backgroundColor: COLORS.GRAY
                }}
                setOpen={(value: boolean) => {}}
            />
            <Loading loading={global?.loading} setLoading={global?.setLoading}/>
            <Notification notification={notification?.notification} setNotification={notification?.setNotification} duration={6000}/>
        </newZoneContext.Provider>
    )
}

export default NewZone;

