export const API = {
    GET: "get",
    POST: "post",
    DELETE: "delete",
    APPLICATION_JSON: "application/json",
    
    SPOTIFY: "https://api.spotify.com/v1",
    SPOTIFY_ACCOUNT: "https://accounts.spotify.com/api/token",
    SPOTIFY_AUTH: "https://accounts.spotify.com/authorize"
}

export const COLORS = {
    GRAY: "#f4f4f4",
    BLACK: "#000000",
    WHITE: "#ffffff",
    ORANGE: "#FF5757",
    LIGHT_GRAY: "#B2B2B2",
    TRANSPARENT: "transparent",
}

export const THEME = {
    PRIMARY: COLORS.WHITE,
    SECONDARY: COLORS.BLACK,
}

export const REGEX = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export const SECTIONS = {
    PLAYLIST: "playlist",
    
    VIBES: "vibes",
    SCHEDULE: "schedule",
    SETTINGS: "settings",
    AI_PLAYLIST: "aiPlaylist",
}

export const DAYS = [
    {name: "Sunday"   , value: "SU"},
    {name: "Monday"   , value: "MO"},
    {name: "Tuesday"  , value: "TU"},
    {name: "Wednesday", value: "WE"},
    {name: "Thursday" , value: "TH"},
    {name: "Friday"   , value: "FR"},
    {name: "Saturday" , value: "SA"}
]

export const DIST_FUNCTIONS = {
    EUCLIDEAN: "euclidean",
}

export const DEFAULTS = {
    DIR: [0, 0],
    VIBES: {
        energy: [{x: 0, y: 0.5}, {x: 0.25, y: 0.5}, {x: 0.5, y: 0.5}, {x: 0.75, y: 0.5}, {x: 1, y: 0.5}],
        mood: [{x: 0, y: 0.5}, {x: 0.25, y: 0.5}, {x: 0.5, y: 0.5}, {x: 0.75, y: 0.5}, {x: 1, y: 0.5}],
    }
}

export const WEIGHTS = {
    ENERGY: 0.8,
    VALENCE: 0.2
}
