import * as React from 'react';

import dayjs from 'dayjs';
import { Line } from "react-chartjs-2";
import dragDataPlugin from 'chartjs-plugin-dragdata';
import { Button, MenuItem, Select } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';

import { styles } from "../styles";
import { COLORS, DAYS } from '../constants';
import { useAriaVibes } from './vibesHooks';
import { getBreakTime, useAriaSchedule } from './scheduleHooks';
import { newZoneContext } from '../pages/newZone';
import { GlobalContext, NotificationContext } from '..';
import { ariaScheduleType, point, propertyType } from '../types';

Chart.register(...registerables, dragDataPlugin);
const defaultData = [{x: 0, y: 0.5}, {x: 0.25, y: 0.5}, {x: 0.5, y: 0.5}, {x: 0.75, y: 0.5}, {x: 1, y: 0.5}]

const AriaVibes = ({...props}) => {
    const zone = React.useContext(newZoneContext);
    const flagVibesUpdate = props.flagVibesUpdate? props.flagVibesUpdate : () => {};
    const global = React.useContext(GlobalContext);
    const notification = React.useContext(NotificationContext);

    const [chartKey, setChartKey] = React.useState(0);
    const [activeVibe, setActiveVibe] = React.useState(defaultData);
    const [activeDay, setActiveDay] = React.useState(new Date().getDay());
    const [activeSchedule, setActiveSchedule] = React.useState<ariaScheduleType>();
    const [activeProperty, setActiveProperty] = React.useState<propertyType>("energy");
    const {vibes, updateVibes, save} = useAriaVibes(zone?.zone, notification?.setNotification, global?.setLoading);
    const {schedules} = useAriaSchedule(vibes, zone?.zone, notification?.setNotification, global?.setLoading);

    // i - index of dataset
    // j - index of point
    const onDrag = (event: any, i: number, j: number, newPoint: point) => {
        // console.log(`[AriaVibes] >> dragging (${i}, ${j})...`)
        if (!activeProperty) return;
        return isValidVibe(activeVibe, j, newPoint)
    }
    const onDragEnd = (event: any, i: number, j: number, newPoint: point) => {
        if (!activeVibe) return;
        if (isValidVibe(activeVibe, j, newPoint)) {
            updateVibes(activeDay, activeProperty, {j: j, point: newPoint});
        } else {
            updateVibes(activeDay, activeProperty, {j: j, point: {x: activeVibe[j + 1].x, y: newPoint.y}});
        }
        console.log(`[AriaVibes][onDragEnd] >> saving ${activeProperty} for ${activeDay}...`)
        save(activeDay, activeProperty);
        return true;
    }
    const onDragStart = async (event: any, i: number, j: number, newPoint: point) => {
        // console.log(`[AriaVibes] >> dragging (${i}, ${j})...`)
        return isValidVibe(activeVibe, j, newPoint);
    }

    React.useEffect(() => {
        if ((typeof activeDay === "number")) {
            if (schedules.initialized) {
                setActiveSchedule(schedules.schedules[activeDay]);
            }

            if (vibes.datasets) {
                setActiveVibe(vibes.datasets[activeDay][activeProperty]);
                setChartKey(chartKey + 1);
            } 
        }
    }, [activeDay, schedules, activeProperty])

    React.useEffect(() => {
        if (!vibes.initialized) return;
        if (vibes.update && vibes.datasets && (typeof activeDay === "number")) {
            setActiveVibe(vibes.datasets[activeDay][activeProperty]);
            flagVibesUpdate(activeDay);
        }
    }, [vibes])

    return (
        <>
        {activeSchedule? 
        <div className="width-100 flex column align-center" style={{maxWidth: styles.maxSectionWidth}}>
            {/* Header */}
            <div className="flex justify-between align-center width-100">
                <div>
                    <Button 
                        variant="contained" 
                        sx={{...styles.button, margin: "0px 5px 0px 0px", ...styles.ariaVibesControls("energy", activeProperty)}} 
                        onClick={() => setActiveProperty("energy")}
                    >
                        Energy
                    </Button>
                    <Button 
                        variant="contained" 
                        // disabled
                        sx={{...styles.button, margin: "0px 0px 0px 5px", ...styles.ariaVibesControls("mood", activeProperty)}} 
                        onClick={() => setActiveProperty("mood")}
                    >
                        Mood
                    </Button>
                </div>
                <Select 
                    value={activeDay} 
                    // disabled
                    style={{
                        ...styles.dropdown, 
                        width: 150,
                        margin: "10px 0px 10px 0"
                    }} 
                    onChange={(event) => setActiveDay(parseInt(event.target.value as string))}
                >
                    {DAYS.map((day, i) => <MenuItem value={i} key={`sd-${i}`}>{day.name}</MenuItem>)}
                </Select>
            </div>

            {/* Graph */}
            <Line 
                key={chartKey}
                options={{
                    ...constructGraphOptions(onDragStart, onDrag, onDragEnd, activeSchedule)
                }} 
                style={{padding: "10px 0 20px 0", maxHeight: 400}}
                data={constructGraphData(JSON.parse(JSON.stringify(activeVibe)))} 
            />

            {/* Time Pickers */}
            <div className="flex align-center justify-between width-100" style={{maxWidth: styles.maxSectionWidth}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker 
                        disabled
                        label="Start"
                        sx={{width: 150, borderRadius: 0}}
                        value={dayjs(activeSchedule.ariaSlots[0].start || "09:00", ["HH:mm"])}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock
                        }}
                    />
                    <TimePicker 
                        disabled
                        label="End"
                        sx={{width: 150}}
                        value={dayjs(activeSchedule.ariaSlots.at(-1)?.end || "17:00", ["HH:mm"])}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock
                        }}
                    />
                </LocalizationProvider>
            </div>
        </div>
        : <></>}
        </>
    )
};

export default AriaVibes;

function constructGraphData(activeVibe: point[]) {
    return {
        // labels: activeProperty?.data?.x || defaultData.x,
        datasets: [{
            data: activeVibe,
            // data: activeProperty?.data?.y || defaultData.y,

            borderWidth: 1,
            pointRadius: 5,
            pointHitRadius: 40,
            pointHoverRadius: 10,
            borderColor: COLORS.BLACK,
            backgroundColor: COLORS.GRAY,
        }]
    }
}
function constructGraphOptions(onDragStart: CallableFunction, onDrag: CallableFunction, onDragEnd: CallableFunction, activeSchedule: ariaScheduleType): any {
    let delayed = false
    return {
        responsive: true,
        dragData: true,

        animation: {
            onComplete: () => {
                delayed = true
            },
            delay: (context: any) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                delay = context.dataIndex * 100 + context.datasetIndex * 100;
                }
                return delay;
            },
        },

        scales: {
            y: {
                min: 0,
                max: 1,

                beginAtZero: true,
                grid: {display: false},
                ticks: {
                    callback: (value: number) => {
                        switch (value) {
                            case 0: return "CALM";
                            case 0.5: return "ENGAGE";
                            case 1.0: return "ENERGIZE";
                            default: return "";
                        }
                    }
                }
            },
            x: {
                min: 0,
                max: 1,
                type: "linear",
                beginAtZero: true,
                grid: {display: false},
                ticks: {callback: () => ""}
            }
        },
        plugins: {
            showTooltip: false,
            legend: {display: false},
            tooltip: {
                enabled: true,
                displayColors: false,
                callbacks: {
                    label: (tooltipItems: any) => {
                        return getBreakTime(tooltipItems.parsed.x*100, activeSchedule, "standard")
                    },
                    title: () => ""
                }
            },

            dragData: {
                dragX: true,
                onDragStart: onDragStart,
                onDrag: onDrag,
                onDragEnd: onDragEnd,
            }
        }
    }
}

function isValidVibe(vibe: point[], j: number, newPoint: point): boolean {
    const nextPoint: point = vibe[j + 1];
    const prevPoint: point = vibe[j - 1];

    // console.log("[isValidVibe] (prevPoint, newPoint, nextPoint) >>", prevPoint, newPoint, nextPoint)
    if (j !== vibe.length - 1) {
        if (nextPoint.x < newPoint.x) return false;
    }

    if (j !== 0) {
        if (prevPoint.x > newPoint.x) return false;
    } 
    
    return true;
}