import { DAYS } from "../constants";
import { ariaVibesType, newZoneType } from "../types";
import { useCustomState } from "../utils";
import { getSoundZoneMetadata, saveAriaVibes } from '../api/firebase';

const initializePlaylist = (start: string, end: string) => ({spotifyId: "", spotifyUris: [], start: start, end: end});
const defaultVibes = {
    mood: [{x: 0, y: 0.5}, {x: 0.25, y: 0.5}, {x: 0.5, y: 0.5}, {x: 0.75, y: 0.5}, {x: 1, y: 0.5}],
    energy: [{x: 0, y: 0.5}, {x: 0.25, y: 0.5}, {x: 0.5, y: 0.5}, {x: 0.75, y: 0.5}, {x: 1, y: 0.5}]

}
export function useAriaVibes(zone?: newZoneType, setNotification?: CallableFunction, setLoading?: CallableFunction) {
    const [vibes, setVibes] = useCustomState<ariaVibesType>({
        datasets: DAYS.map(day => JSON.parse(JSON.stringify(defaultVibes)))
    });

    if (!vibes.initialized) {
        const initializeVibesWrapper = async () => {
            if (!zone) return;
            setVibes({initialized: true});

            // const response = await appUser?.getVibeMap();
            const zoneMetaData = await getSoundZoneMetadata(zone.id);
            if (zoneMetaData) {
                const fetchedVibes = zoneMetaData?.ariaVibes;
                
                console.log("[useAriaVibes] (fetchedVibes) >>", fetchedVibes); 
                if (fetchedVibes) {
                    setVibes({...fetchedVibes, update: true})
                }
                else setVibes({update: true});
            } else {
                if (setNotification) setNotification({value: "Failed to load vibes", notify: true})
            }
        }
        initializeVibesWrapper();
    }

    const updateVibes = async (activeDay: number | undefined, label: "mood" | "energy" | undefined, {...props}) => {
        const { j, point } = props;

        if (typeof activeDay === "number" && typeof label === "string") {
            const updatedDatasets = vibes.datasets;
            const n = updatedDatasets[activeDay][label].length;
            
            if (j === 0 || j === n - 1) {
                updatedDatasets[activeDay][label][j].y = point.y;
            } else {
                updatedDatasets[activeDay][label][j] = point;
            } 

            setVibes({
                update: true, 
                datasets: updatedDatasets
            })
        }
    }

    const save = async (activeDay: number, label: "energy" | "mood") => {
        if (!zone || !vibes.datasets) {
            if (setNotification) setNotification({value: "Failed to save vibes", notify: true});
            return;
        }

        const points = vibes.datasets[activeDay][label];
        if (points) {
            // const response = await appUser?.saveVibePoints(zone.id, DAYS[activeDay].name.toLowerCase(), label, points);
            const response = await saveAriaVibes(zone.id, vibes);
            console.log("[useAriaVibes] (saved) >>", response);
        }
    }

    return {vibes, updateVibes, save}
}
