import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
    query MyQuery($first: Int) {
        me {
        ...on PublicAPIClient {
                accounts(first: $first) {
                    edges {
                        node {
                            businessName
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ZONES = gql`
    query MyQuery($first: Int!, $id: ID!) {
        location(id: $id) {
            soundZones(first: $first) {
                edges {
                    node {
                        id
                        name
                        schedule {
                            id
                        }
                    }
                }
            }
        }
    }
`

export const GET_LOCATIONS = gql`
    query MyQuery($first: Int!, $id: ID!) {
        account(id: $id) {
            locations(first: $first) {
                edges {
                    node {
                        id
                        name
                        isoCountry
                        city
                        address
                        postalCode
                        state
                    }
                }
            }
        }
    }
`

export const GET_NOW_PLAYING = gql`
    query MyQuery($id: ID = "") {
        nowPlaying(soundZone: $id) {
            track {
                id
                name
                album {
                    image {
                        url
                    }
                    colors {
                        primary
                        accent
                    }
                }
                artists {
                    name
                }
                duration
            }
            playFrom {
                ... on Playlist {
                    id
                    name
                }
                ... on Schedule {
                    id
                    playlists {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const GET_PLAYLIST = gql`
    query MyQuery($market: IsoCountry = US, $first: Int = 10, $id: ID = "") {
        playlist(id: $id) {
            name
            id
            tracks(first: $first) {
                edges {
                    node {
                        id
                        name
                        explicit
                        duration
                        artists {
                            name
                        }
                        album {
                            image {
                                url
                            }
                            colors {
                                primary
                                accent
                            }
                        }
                        isAvailable(market: $market)
                    }
                }
            }
        }
    }
`

export const GET_SCHEDULED_PLAYLIST = gql`
    query MyQuery($id: ID = "") {
        schedule(id: $id) {
            slots {
                playlistIds
                start
                duration
                rrule
            }
            playlists {
                tracks(first: 1000) {
                    edges {
                        node {
                            id
                            name
                            duration
                            artists {
                                name
                            }
                            album {
                                image {
                                    url
                                }
                            }
                            isAvailable(market: US)
                            explicit
                        }
                    }
                }
                id
                name
                display{
                    image{
                        placeholder
                    }
                }
            }
        }
    }
` 

export const GET_ZONE_STATUS = gql`
    query MyQuery($id: ID!) {
        soundZone(id: $id) {
            isPaired
            online
            device {
                playback {
                    state
                    volume
                }
            }
            account {
                settings {
                    filterExplicit
                }
            }
        }
    }
`