import { auth, db } from '../api/firebase'; 
import React, { useContext, useState, ReactNode } from 'react';

import { signInWithEmailAndPassword, signOut, onAuthStateChanged, updatePassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

export interface ZoneType {
  id: string;
  name: string;
  schedule_id: string;
}

export class AppUser {
  isAuthenticated: boolean | null;
  setAuthenticated: (state: boolean | null) => void;
  assignedLocation: string[] | null;
  clearance: number | null;
  email: string | null;
  sybAccount: string | null;
  vibeMap: any | null;
  blockExplicit: boolean | false;
  currentZone: ZoneType | null;

  constructor() {
    this.isAuthenticated = null;
    this.setAuthenticated = (s) => {};
    this.assignedLocation = null;
    this.email = null;
    this.sybAccount = null;
    this.clearance = null;
    this.vibeMap = null;
    this.blockExplicit = false;
    this.currentZone = null;

    onAuthStateChanged(auth, (user) => {
      // console.log("changed")
      this.setAuthenticated(user ? true : false)
    });
  }

  login = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return null;
    } catch (error) {
      console.error(error);
      return error
    }
  };

  logout = async () => {
    await signOut(auth);
  };

  needsPasswordChange = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return null;

    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      // First time login
      await setDoc(userDocRef, { firstLogin: new Date(), needsPasswordUpdate: true });
      return true;
    } else {
      const userData = userDocSnap.data();
      if (userData.needsPasswordUpdate) {
        return true;
      } else {
        return false;
      }
    }
  }

  updatePassword = async (password : string) => {
    if (auth.currentUser === null) return;
    try {
        await updatePassword(auth.currentUser, password);
        console.log("Password updated successfully.");
        try {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            await setDoc(userDocRef, {
                needsPasswordUpdate: false
            }, { merge: true });
            console.log("Password reset status updated successfully.");
            return null
        } catch (error : any) {
            console.error("Error updating document:", error);
            return error;
        }
    } catch (error : any) {

      if (error.code === 'auth/requires-recent-login') {
        console.error('error signing out')
        auth.signOut();
      }

      return error
    }
  }

  sendResetPasswordEmail = async (email : string) => {
    try {
      await sendPasswordResetEmail(auth, email)
    } catch (error) {
      console.error(error)
      return error;
    }
  }
  
  fetchUserDataFromFirebase = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.warn('User is not logged in. Failed to fetch!')
      return null;
    }
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    const data = userDocSnap.data();
    if (data) {
      this.sybAccount = data.sybAccount;
      this.assignedLocation = data.assignedLocation;
      this.email = data.email;
      this.sybAccount = data.sybAccount;
      this.clearance = data.clearance;
      this.vibeMap = data.vibeMap;
      this.blockExplicit = (data.explicit) ? data.explicite : false;
    }
  }

  getSybAccount = async () => {
    if (this.sybAccount === null) 
      await this.fetchUserDataFromFirebase();
    return this.sybAccount;
  }

  getAssignedLocation = async () => {
    if (this.assignedLocation === null) 
      await this.fetchUserDataFromFirebase();
    return this.assignedLocation;
  }

  getClearance = async () => {
    if (this.clearance === null) 
      await this.fetchUserDataFromFirebase();
    return this.clearance;
  }

  updateBlockExplicit = (value: boolean) => {
    this.blockExplicit = value;
  }
}

export type AppUserContextType = AppUser | null;
interface AppUserProviderProps {
  children: ReactNode;
}
export const AppUserContext = React.createContext<AppUserContextType>(null);

export function AppUserProvider({ children } : AppUserProviderProps) {
  const appUser = new AppUser();
  [appUser.isAuthenticated, appUser.setAuthenticated] = useState<null | boolean>(null)

  return (
    <AppUserContext.Provider value={appUser}>
      {children}
    </AppUserContext.Provider>
  );
}

export const useAppUser = (): AppUserContextType => useContext(AppUserContext);
