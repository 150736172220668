import { userType } from "../types";
import { UserInterface } from "../interfaces";
import { GenericClass } from "./genericClass";
import { getCollection } from "../api/newFirebase";

export class User extends GenericClass<userType> implements UserInterface {
    collectionId = "users"

    constructor(uid: string, user: userType) {
        super(uid);
        this.obj = user;
    }

    static async getAllUsers(): Promise<[string, userType][]> {
        return await getCollection<userType>("users")
    }
}