import * as React from "react";

import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@mui/material";

import { styles } from "../styles";
import { useAuth } from "../context/newAuth";
import { getDevOrDepUrl } from "../utils";

function Settings({...props}) {
    const auth = useAuth();
    const navigate = useNavigate();
    const selectedLocation = props.location;

    return (
        <div className="flex column align-center" style={{width: "100%"}}>
            <div className="flex justify-around width-100 wrap">
                <div className="flex column">
                    <div className="flex column align-center" style={{margin: 20}}>
                        <Typography fontSize={24} style={{margin: "0 0 2px 0", textAlign: "center"}}>Account</Typography>
                        <Typography style={{textAlign: "center"}}>{auth?.user?.obj?.email}</Typography>
                    </div>

                    <div className="flex column align-center" style={{margin: 20}}>
                        <Typography fontSize={24} style={{margin: "0 0 2px 0", textAlign: "center"}}>Need Help?</Typography>
                        <Typography style={{textAlign: "center"}}><a href="mailto:support@ariasound.studio">support@ariasound.studio</a></Typography>
                        <Typography style={{textAlign: "center"}}>+1 (337) 781-0837</Typography>
                    </div>
                </div>

                <div className="flex column align-center" style={{margin: 20}}>
                    <Typography fontSize={24} style={{margin: "0 0 2px 0", textAlign: "center"}}>Location</Typography>
                    <Typography style={{textAlign: "center"}}>{selectedLocation?.name}</Typography>
                    <Typography style={{textAlign: "center"}}>{selectedLocation?.address}, {selectedLocation?.state} {selectedLocation?.isoCountry} {selectedLocation?.postalCode}</Typography>
                </div>
            </div>
            <Button 
                sx={{...styles.button, marginTop: "75px"}} 
                onClick={async () => {
                    await auth?.logout(); 
                    const url = getDevOrDepUrl("aria");
                    if (url) navigate(url)
                }}
            >Logout</Button>
        </div>
    )
}

export default Settings;
