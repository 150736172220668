import { useMutation, useQuery } from "@apollo/client";

import { GET_ACCOUNTS, GET_LOCATIONS, GET_NOW_PLAYING, GET_PLAYLIST, GET_SCHEDULED_PLAYLIST, GET_ZONES, GET_ZONE_STATUS } from "../queries";
import { LOAD_SPOTIFY_PLAYLIST, PAUSE, PLAY, PLAY_FROM, PLAY_TRACK, SKIP, UPDATE_SCHEDULE, LINEAR_PLAY, SET_VOLUME, QUEUE_TRACKS, CLEAR_QUEUED_TRACKS } from "../mutations";


export function FetchAccounts(): CallableFunction {
    const { error, refetch } = useQuery(GET_ACCOUNTS, {skip: true});
    if (error != null) console.error(error);
    return sybWrapper(refetch);
}

export function FetchLocations(): CallableFunction {
    const { error, refetch } = useQuery(GET_LOCATIONS, {skip: true});
    if (error != null) console.error(error);
    return sybWrapper(refetch);
}

export function FetchZones(): CallableFunction {
    const { error, refetch } = useQuery(GET_ZONES, {skip: true});
    if (error != null) console.error(error);
    return sybWrapper(refetch);
}

export function FetchZoneStatus(): CallableFunction {
    const { error, refetch } = useQuery(GET_ZONE_STATUS, {skip: true});
    if (error != null) console.error(error);
    return sybWrapper(refetch);
}

export function Play(): CallableFunction {
    const [play] = useMutation(PLAY);
    return sybWrapper(play);
}

export function Pause(): CallableFunction {
    const [pause] = useMutation(PAUSE);
    return sybWrapper(pause);
}

export function SetVolume(): CallableFunction {
    const [setVolume] = useMutation(SET_VOLUME);
    return sybWrapper(setVolume);
}

export function Skip(): CallableFunction {
    const [skip] = useMutation(SKIP);
    return sybWrapper(skip);
}

export function PlayTrack(): CallableFunction {
    const [playTrack] = useMutation(PLAY_TRACK);
    return sybWrapper(playTrack);
}

export function FetchNowPlaying(): CallableFunction {
    const { error, refetch } = useQuery(GET_NOW_PLAYING, {skip: true});
    if (error != null) console.error(error);
    return sybWrapper(refetch);
}

export function FetchSYBPlaylist(): CallableFunction {
    const { error, refetch } = useQuery(GET_PLAYLIST, {skip: true});
    if (error != null) console.error(error);
    return sybWrapper(refetch);
}

export function LoadSpotifyPlaylist(): CallableFunction {
    const [loadSpotifyPlaylist] = useMutation(LOAD_SPOTIFY_PLAYLIST);
    return sybWrapper(loadSpotifyPlaylist);
}

export function UpdateSYBSchedule(): CallableFunction {
    const [updateSchedule] = useMutation(UPDATE_SCHEDULE);
    return sybWrapper(updateSchedule);
}

export function PlayFrom(): CallableFunction {
    const [playFrom] = useMutation(PLAY_FROM);
    return sybWrapper(playFrom);
}


export function GetScheduledPlaylist(): CallableFunction {
    const { error, refetch } = useQuery(GET_SCHEDULED_PLAYLIST, {skip: true});
    if (error != null) console.error(error);
    return sybWrapper(refetch);
}

export function LinearPlay(): CallableFunction {
    const [linearPlay] = useMutation(LINEAR_PLAY);
    return sybWrapper(linearPlay);
}

export function QueueTracks(): CallableFunction {
    const [queueTracks] = useMutation(QUEUE_TRACKS);
    return sybWrapper(queueTracks);
}

export function ClearQueueTracks(): CallableFunction {
    const [clearQueueTracks] = useMutation(CLEAR_QUEUED_TRACKS);
    return sybWrapper(clearQueueTracks);
}

export function sybWrapper(callback: CallableFunction): CallableFunction {
    return async (variables: object) => {
        for (const [variable, value] of Object.entries(variables)) {
            // console.log(`[sybWrapper] (${variable}: value) >>`, value)
            if (value === null) throw new Error(`[sybWrapper] (${variable}: value) >>`, value);
        }
        try {
            const response = await callback(variables);
            // console.log(response)
            return response
        } catch (error) {
            console.error(error)
        }
    }
}