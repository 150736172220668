import * as React from "react";

import { Container } from "react-bootstrap";
import { IconButton, Slider } from "@mui/material";

import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOff from '@mui/icons-material/VolumeOff';
import VolumeDown from '@mui/icons-material/VolumeDown';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import { styles } from "../styles";
import { COLORS } from "../constants";
import { newZoneContext } from "../pages/newZone";
import { Notification, PausePlayButton, TrackInfo } from "../support";
import { findNowPlayingTrack, resize, sleep } from "../utils";
import { Pause, Play, PlayFrom, PlayTrack, SetVolume, Skip } from "../api/syb";
import { newNowPlayingType, newPlaybackType, newPlaylistsType } from "../types";
import { saveSYBZoneVolume } from "../api/firebase";
import { NotificationContext } from "..";

function Controls({...props}) {
    const zone = React.useContext(newZoneContext);
    const notification = React.useContext(NotificationContext);
    const playback: newPlaybackType = props.playback as newPlaybackType;
    const playlists: newPlaylistsType = props.playlists as newPlaylistsType;
    const nowPlaying: newNowPlayingType = props.nowPlaying as newNowPlayingType;
    
    const skip = Skip();
    const play = Play();
    const pause = Pause();
    const playFrom = PlayFrom();
    const playTrack = PlayTrack();
    const updateSYBVolume = SetVolume();
    const [volume, setVolume] = React.useState(0);
    const [expand, setExpand] = React.useState(false);

    const checkZoneOnlineWrapper = (callback: any) => {
        if (zone?.zone.online) {
            return callback;
        } else {
            return () => notification?.setNotification({value: "Can only set volume when zone is online.", notify: true});;
        }
    }

    const prev = async (event: any) => {
        const i = findNowPlayingTrack(playlists, nowPlaying.playFrom, nowPlaying.track.name, "prev", "syb", zone?.zone.filterExplicit);
        if (nowPlaying.playFrom.playlist) {
            if (typeof i === "number") {
                await playTrack({variables: {
                    soundZones: [zone?.zone.id],
                    playlistId: nowPlaying.playFrom.playlist.id, 
                    sourceTrackIndex: i
                }});
            }
        } else if (nowPlaying.playFrom.schedule) {
            if (typeof i === "number") {
                await playTrack({variables: {
                    soundZones: [zone?.zone.id],
                    playlistId: nowPlaying.playFrom.schedule.playlist.id, 
                    sourceTrackIndex: i
                }});
            }
            await sleep(3000);
            await playFrom({variables: {soundZone: zone?.zone.id, source: zone?.zone.scheduleId}})
        }
        event.stopPropagation()
    }
    const next = async (event: any) => {
        const i = findNowPlayingTrack(playlists, nowPlaying.playFrom, nowPlaying.track.name, "next", "syb", zone?.zone.filterExplicit);
        if (nowPlaying.playFrom.schedule && typeof i === "number") {
            await playTrack({variables: {
                soundZones: [zone?.zone.id],
                playlistId: nowPlaying.playFrom.schedule.playlist.id, 
                sourceTrackIndex: i
            }});
            await sleep(3000);
            await playFrom({variables: {soundZone: zone?.zone.id, source: zone?.zone.scheduleId}})
        } else await skip({variables: {id: zone?.zone.id}});

        event.stopPropagation()
    }
    const saveVolume = async (volume: number) => {
        if (zone?.zone) {
            const response = await saveSYBZoneVolume(zone.zone.id, volume);
            console.log("[controls] (logged volume) >>", response);
        }  
    }
    const updateVolume = async (event: any,  value: number | number[]) => {
        switch (typeof value) {
            case "number":
                setVolume(value);
                saveVolume(value);
                await updateSYBVolume({variables: {id: zone?.zone.id, volume: Math.ceil((value / 100) * 16)}});
                break;
            default: break;
        }
        event.stopPropagation()
    }


    /*** Keep watch for volume changes ***/
    React.useEffect(() => {
        if (zone?.zone.initialized && typeof playback.volume === "number") {
            saveVolume(Math.ceil(100 * playback.volume / 16));
            setVolume(Math.ceil(100 * playback.volume / 16));
        }
    }, [zone?.zone, playback]);

    React.useEffect(() => {
        if (resize(zone?.width, zone?.orientation)) setExpand(false);
    }, [zone?.width, zone?.orientation]);

    return (
        <Container 
            style={{
                ...styles.playbackControl(expand), 
                background: `linear-gradient(
                    to bottom right, 
                    ${expand? nowPlaying.track?.color?.primary || COLORS.BLACK : COLORS.BLACK},
                    ${expand? nowPlaying.track?.color?.accent || COLORS.BLACK : COLORS.BLACK}
                )`,
            }} 
            onClick={() => {
                if (!resize(zone?.width, zone?.orientation)) setExpand(true);
            }}
            className={`flex align-center ${expand? "column justify-center" : "justify-between"} width-100 fixed`}
        >
            {expand? 
                <IconButton 
                    onClick={(event) => {setExpand(false); event.stopPropagation()}}
                    style={{top: 35, left: "calc(100% - 75px)", position: "fixed", color: COLORS.GRAY}}
                >
                    <CloseFullscreenIcon style={{width: 30, height: 30}}/>
                </IconButton> 
            : <></>}

            <div className={`flex align-center ${expand? "column text-center" : ""}`} style={{...styles.trackInfo(expand)}}>
                {nowPlaying.track?.cover? <img src={nowPlaying.track?.cover} style={{width: expand? 250 : 75, height: expand? 250 : 75}} alt=""/> : <></>}
                <TrackInfo 
                    style={{
                        margin: expand? "20px 0 20px 0" : "0 0 0 10px", 
                        color: COLORS.GRAY
                    }} 
                    titleFontSize={expand? 28 : 16}
                    track={nowPlaying?.track}
                />
            </div>

            <div>
                <IconButton onClick={checkZoneOnlineWrapper(prev)}>
                    <SkipPreviousIcon style={{...styles.nextPrev}}/>
                </IconButton>
                <PausePlayButton 
                    play={checkZoneOnlineWrapper(async (event: any) => {
                        await play({variables: {id: zone?.zone.id}});
                        event.stopPropagation()
                    })}
                    pause={checkZoneOnlineWrapper(async (event: any) => {
                        await pause({variables: {id: zone?.zone.id}});
                        event.stopPropagation()
                    })} 
                    state={playback.state} 
                    playStyle={styles.playPause(expand)}
                    pauseStyle={styles.playPause(expand)}
                />
                <IconButton onClick={checkZoneOnlineWrapper(next)}>
                    <SkipNextIcon style={{...styles.nextPrev}}/>
                </IconButton>
            </div>

            {resize(zone?.width, zone?.orientation) || expand? 
                <div className="flex align-center" style={{width: expand? "65%" : 200, maxWidth: 600, marginTop: expand? 20 : 0}}>
                    {!volume?  <VolumeOff style={{color: COLORS.GRAY}} onClick={checkZoneOnlineWrapper((event: any) => updateVolume(event, 50))} className="pointer"/> : <></>}
                    {volume > 0 && volume <= 50? <VolumeDown style={{color: COLORS.GRAY}} onClick={checkZoneOnlineWrapper((event: any) => updateVolume(event, 0))} className="pointer"/> : <></>}
                    {volume > 50 && volume <= 100? <VolumeUp style={{color: COLORS.GRAY}} onClick={checkZoneOnlineWrapper((event: any) => updateVolume(event, 0))} className="pointer"/> : <></>}

                    <Slider value={volume} onChangeCommitted={checkZoneOnlineWrapper(updateVolume)} style={{color: COLORS.WHITE, margin: "0 15px 0 15px"}}/>
                </div> 
            : <></>}
            <Notification notification={notification?.notification} setNotification={notification?.setNotification}/>
        </Container>
    )
}

export default Controls;
