import * as React from "react";

import { IconButton } from "@mui/material";

import { styles } from "../styles";
import { COLORS } from "../constants";

function Nav({...props}) {
    const { icon: IconComponent, style } = props;

    return (
        <IconButton style={{...styles.navProfileButton, ...style}} onClick={props.onClick}>
            <IconComponent 
                style={{
                    width: '100%', 
                    height: '100%', 
                    display: 'flex', 
                    color: COLORS.BLACK,
                    borderRadius: '50%', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    border: '1px solid black',
                }} 
            />
        </IconButton>
    );
}

export default Nav;
