import * as React from "react";

import { Container } from "react-bootstrap";
import { Button, Checkbox, IconButton, MenuItem, Select, Typography } from "@mui/material";

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import "../assets/utils.css";
import { styles } from "../styles";
import { COLORS, THEME } from "../constants";
import { User } from "../classes/user";
import { FormField } from "../forms/fields";
import { createAriaUser, deleteAriaUser, getPlaybackLog } from "../api/aria";
import { useAuth } from "../context/newAuth";
import CustomTable from "../components/table";
import { useNavigate } from "react-router-dom";
import { deleteFromCollection, saveToCollection } from "../api/newFirebase";
import { clearCookies, getDevOrDepUrl, objArrayToCSV, useCustomState } from "../utils";
import { GlobalContext, NotificationContext } from "..";
import { FetchAccounts, FetchLocations } from "../api/syb";
import { Confirm, Loading, Notification, useScreenOrientation, useViewport } from "../support";
import { LoadingContextType, NotificationContextType, confirmType, locationType, sybAccountType, userType } from "../types";


function Admin({...props}) {
    const auth = useAuth();
    const navigate = useNavigate();
    const loading = React.useContext(GlobalContext) as LoadingContextType;
    const notification = React.useContext(NotificationContext) as NotificationContextType;

    const {width} = useViewport();
    const fetchAccounts = FetchAccounts();
    const fetchLocations = FetchLocations();
    const orientation = useScreenOrientation();
    
    const [initialized, setInitialized] = React.useState(false);
    const [confirm, setConfirm] = useCustomState<confirmType>({});
    const [users, setUsers] = React.useState<[string, userType][]>([]);
    const [newUser, setNewUser] = React.useState<userType | null>(null);
    const [sybAccounts, setSybAccounts] = React.useState<sybAccountType[]>();
    const [sybLocations, setSybLocations] = React.useState<Map<string, locationType[]>>();
    const [editedUser, setEditedUser] = React.useState<[number, userType]>([-1, {} as userType]);

    const cancelConfirm = () => {
        if (confirm.callback) confirm.callback();

        setConfirm({
            required: false, 
            editing: undefined,
            callback: undefined,
            openDialogue: false,
        })
    }

    const confirmRequired = (callback?: CallableFunction, required?: boolean, title?: string, content?: string) => {
        if (confirm.required || required) {
            setConfirm({
                openDialogue: true, 
                callback: callback,
                title: title,
                content: content
            });
        } else if (callback) callback();
    }

    React.useEffect(() => {
        if (auth?.user instanceof User) {
            if (!auth.user.obj?.superadmin) {
                const url = getDevOrDepUrl("aria");
                if (url) navigate(url);
                return
            }
            const initializeUsersWrapper = async () => {
                setUsers(await User.getAllUsers())
            }
            initializeUsersWrapper()
        } 
    }, [auth?.user])

    React.useEffect(() => {
        if (!initialized) {
            clearCookies()
            setInitialized(true);
            const initializeSybAccountsWrapper = async () => {
                loading.setLoading({load: true, message: "Loading accounts..."})
                const accountsResponse = await fetchAccounts({first: 1000})
                console.log("[Admin] (accountsResponse) >>", accountsResponse)
                if (accountsResponse) {
                    const accounts: sybAccountType[] = accountsResponse.data.me.accounts.edges.map((edge: any) => ({id: edge.node.id, name: edge.node.businessName}))
                    const locations = new Map()
    
                    for (const account of accounts) {
                        const locationsResponse = await fetchLocations({first: 100, id: account.id})
                        if (locationsResponse) {
                            locations.set(account.id, locationsResponse.data.account.locations.edges.map((edge: any) => edge.node as locationType))
                        }
                    }
                    setSybLocations(locations);
                    setSybAccounts(accounts);
                }
                loading.setLoading({load: false, message: null})
            }
            initializeSybAccountsWrapper()
        }
    }, [initialized])

    const save = async (id: string) => {
        const userBeingEdited = editedUser[1];

        loading.setLoading({load: true, message: "Updating user..."})
        setUsers(users.map((user, j) => id !== user[0]? user : [id, userBeingEdited]))

        await saveToCollection(id, "users", userBeingEdited, {})
        loading.setLoading({load: false, message: null})
        notification?.setNotification({notify: true, value: "User updated!"})
        
        setEditedUser([-1, {} as userType]);
    }

    const createUser = async () => {
        console.log(newUser)
        if (!newUser) return;
        // if (!newUser.email) return;
        const emailField = document.getElementById("userEmail-new");

        let email = undefined;
        if (emailField) {
            email = (emailField as HTMLInputElement).value
        }
        if (!email) return 
        loading.setLoading({message: "Creating user...", load: true})
        const response = await (await createAriaUser(email, "AriaPass123")).json()

        if (response) {
            const creationStatus = await saveToCollection(response.uid, "users", {...newUser, email: email}, {});
            if (creationStatus) {
                notification?.setNotification({value: "User created, Your temp password is: AriaPass123", notify: true});
                setUsers([[response.uid, {...newUser, email: email}], ...users])
                setNewUser(null);
            }
        }
        loading.setLoading({message: null, load: false})
    }

    const downloadPlaybackLog = async () => {
        loading.setLoading({load: true, message: "Fetching playback log..."})
        let i = 0; let j = 12e3 - 1;

        let playbackLog = [];

        while (true) {
            const fetchedData = (await (await getPlaybackLog(i, j)).json()).playbackLog
            console.log("[Admin] (fetchedData) >>", fetchedData)

            playbackLog.push(...fetchedData)
            if (fetchedData.length < (j - i)) break;
            else {
                i += 12e3;
                j += 12e3;
            }
        }

        if (playbackLog) {
            loading.setLoading({load: true, message: "Downloading playback log..."})
            
            const csvData = objArrayToCSV(playbackLog);
            const blob = new Blob([csvData], {type: 'text/csv'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
    
            a.href = url;
            a.download = 'playbackLog.csv';
            document.body.appendChild(a);
            a.click();
        }
        
        loading.setLoading({load: false, message: null})
        notification?.setNotification({value: "Playback log downloaded..."})
    }

    const deleteUser = async (id: string) => {
        const response = await deleteAriaUser(id)
        console.log(response)
        if (response) {
            const deleteRecord = await deleteFromCollection(id, "users");
            if (deleteRecord) {
                notification?.setNotification({value: "User deleted", notify: true})
            }
        }
    }

    return (
        <>
            <Container fluid className={`width-100 flex height-100 align-center column ${sybAccounts && sybLocations? "justify-end" : "justify-center"}`}>
                <Typography fontSize={36} color={COLORS.ORANGE} style={{height: "50px"}}>ARIA</Typography>
                <div style={{marginBottom: 10}} className="flex align-center">
                    <Button sx={{...styles.button, width: 200, margin: "0 5px 0 5px"}} onClick={downloadPlaybackLog}>Download Playback</Button>
                    <Button sx={{...styles.button, width: 200, margin: "0 5px 0 5px"}} onClick={() => setNewUser({} as userType)}>Add User</Button>
                </div>
                <div 
                    className="flex justify-between align-center" 
                    style={{...styles.adminHeaderStyle}}
                >
                    <Typography fontSize={12} style={{width: 30}}></Typography>
                    <Typography fontSize={12} style={{width: 275}} className="text-center">Email</Typography>
                    <Typography fontSize={12} style={{width: 100}} className="text-center">Syb Account</Typography>
                    <Typography fontSize={12} style={{width: 100}} className="text-center">Clearance</Typography>
                    <Typography fontSize={12} style={{width: 275}} className="text-center">Assigned Location</Typography>
                    <Typography fontSize={12} style={{width: 40}} className="text-center">Super Admin</Typography>
                    <Typography fontSize={12} style={{width: 90}} className="text-center">Actions</Typography>
                </div>
                {sybAccounts && sybLocations? 
                <>
                    {newUser?
                        <AdminRow 
                            highlight
                            i={null}
                            id={null}
                            user={newUser}
                            save={createUser}
                            style={{width: "80%"}}
                            sybAccounts={sybAccounts}
                            sybLocations={sybLocations}
                            editedUser={[null, newUser]}
                            deleteUser={() => {setNewUser(null)}}
                            formFieldStyle={{border: `1px solid ${COLORS.ORANGE}`}}
                            setEditedUser={([i, user]: [number, userType]) => setNewUser(user)}
                        /> 
                    : <></>}
                    <CustomTable 
                        rows={users}
                        cells={([id, user]: [string, userType], i: number) => 
                            <AdminRow 
                                i={i}
                                id={id}
                                save={save}
                                user={user}
                                editedUser={editedUser}
                                sybAccounts={sybAccounts}
                                sybLocations={sybLocations}
                                setEditedUser={setEditedUser}
                                deleteUser={() => confirmRequired(() => {
                                    deleteUser(id);
                                    setUsers(users.filter(user => user[0] !== id))

                                }, true)}
                            />
                        }
                        backgroundColor={COLORS.GRAY}
                        style={{...styles.adminTable(width, orientation), ...props.style}}                
                    />
                </>
                : <></>}
            </Container>
            <Loading loading={loading?.loading} setLoading={loading?.setLoading}/>
            <Notification notification={notification?.notification} setNotification={notification?.setNotification}/>
            <Confirm 
                disagreeText="Cancel"
                agreeAutoFocus={false}
                handleDisagree={() => setConfirm({openDialogue: false})}
                title={confirm.title? confirm.title : "Are you sure you want to delete user?"}
                content={confirm.content? <>{confirm.content}</> : <></>}
                agreeText={<Typography style={{color: COLORS.ORANGE, fontSize: "0.875rem"}}>Confirm</Typography>}
                handleAgree={() => cancelConfirm()}
                
                open={confirm.openDialogue || false} 
                style={{
                    borderRadius: 0,
                    backgroundColor: COLORS.GRAY
                }}
                setOpen={(value: boolean) => {}}
            />
        </>
    )
}

export default Admin;

function AdminRow({...props}) {
    const {editedUser, sybLocations, sybAccounts, setEditedUser, save, deleteUser, id, user, i, style, highlight, formFieldStyle} = props;

    const userBeingEdited = editedUser[1];
    const disabled = !highlight && editedUser[0] !== i;
    const locations = sybLocations?.get(user?.sybAccount)

    return (
        <div
            style={{...style}}
            key={`${user.email}`}
            className={`flex align-center justify-between`}
        >
            <Typography fontSize={12} style={{width: 30, display: "flex"}}>{typeof i === "number"? i + 1 : 0}</Typography>
            <FormField 
                variant="outlined" 
                placeholder="Email"
                value={user.email}
                id={`userEmail-${typeof i === "number"? i : "new"}`}
                inputStyle={{fontSize: 12}}
                disabled={highlight? false : true}
                style={{...styles.formField, ...formFieldStyle}}
            />
            <Select
                disabled={disabled}
                value={disabled? sybAccounts? user.sybAccount : "" : userBeingEdited.sybAccount || ""}
                style={{...styles.adminSelect, ...formFieldStyle}}
                onChange={(event: any) => {
                    setEditedUser([i, {...userBeingEdited, sybAccount: event.target.value}])
                }}
            >
                {sybAccounts?.map((sybAccount: sybAccountType) => {
                    return (
                        <MenuItem value={sybAccount.id} key={sybAccount.id} sx={{fontSize: 12}}>{sybAccount.name}</MenuItem>
                    )
                })}
            </Select>
            <Select
                disabled={disabled}
                value={disabled? user.clearance || 0 : userBeingEdited.clearance || 0}
                style={{...styles.adminSelect, ...formFieldStyle}}
                onChange={(event: any) => {
                    setEditedUser([i, {...userBeingEdited, clearance: event.target.value}])
                }}
            >
                <MenuItem value={0} sx={{fontSize: 12}}>Employee</MenuItem>
                <MenuItem value={1} sx={{fontSize: 12}}>Admin</MenuItem>
            </Select>
            <Select
                multiple
                disabled={disabled}
                style={{...styles.adminSelect, width: 275, ...formFieldStyle}}
                value={disabled? user.assignedLocation || ["All"] : userBeingEdited.assignedLocation || ["All"]}
                onChange={(event: any) => {
                    setEditedUser([i, {...userBeingEdited, assignedLocation: event.target.value}])
                }}
            >
                <MenuItem value={"All"} sx={{fontSize: 12}}>All</MenuItem>
                {locations?.map((location: locationType) => {
                    return (
                        <MenuItem value={location.id} key={location.id} sx={{fontSize: 12}}>{location.name}</MenuItem>
                    )
                })}
            </Select>
            <Checkbox 
                disabled={disabled} 
                checked={disabled? user.superadmin || false : userBeingEdited.superadmin || false} 
                onChange={(event) => {
                    setEditedUser([i, {...userBeingEdited, superadmin: event.target.checked}])
                }}
            />
            <div>
                {disabled?
                    <IconButton style={{height: 40, width: 40}} onClick={() => {setEditedUser([i, user])}}>
                        <EditIcon sx={{color: COLORS.LIGHT_GRAY}}/>
                    </IconButton>
                    :
                    <IconButton 
                        onClick={() => save(id)}
                        style={{height: 40, width: 40}} 
                    >
                        <SaveIcon sx={{color: COLORS.LIGHT_GRAY}}/>
                    </IconButton>
                }
                <IconButton style={{height: 40, width: 40, marginLeft: 10}} onClick={() => deleteUser()}>
                    <DeleteIcon sx={{color: COLORS.ORANGE}}/>
                </IconButton>
            </div>
        </div>
    )
}