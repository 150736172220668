import { COLORS } from "./constants"
import { resize } from "./utils"

export const styles = {
    maxSectionWidth: 1400,
    
    formField: {
        width: 275,
        margin: 7.5,
        fontSize: 20,
    },

    button: {
        width: 275,
        borderRadius: 0,
        boxShadow: "none",
        color: COLORS.WHITE,
        margin: `7.5px 0 0 0`,
        textTransform: "none",
        backgroundColor: COLORS.BLACK,
        '&:hover': {
            backgroundColor: '#FF5757',
        },
        '&:active': {
            backgroundColor: '#FF5757',
        },

    },

    dropdown: {
        height: 40,
        borderRadius: 0
    },

    zoneButton: {
        width: 150,
        height: 150,
        fontSize: 16,
        borderRadius: 0,
        color: COLORS.BLACK,
        margin: "10px 15px 10px 15px",
        border: `1px solid ${COLORS.BLACK}`
    },

    playbackControl: (expand: boolean) => ({
        bottom: 0,
        zIndex: 2,
        width: "100%",
        // minWidth: 1275,
        // paddingBottom: 15,
        background: COLORS.BLACK,
        height: expand? "100vh" : 90, 
    }),

    trackInfo: (expand: boolean) => ({
        margin: "5px 10px 5px 20px", 
        width: expand? "100%" : 200
    }),
    
    playPause: (expand: boolean) => ({
        width: expand? 100 : 40, 
        height: expand? 100 : 40,
        color: COLORS.WHITE
    }),

    nextPrev: {
        width: 40,
        height: 40,
        color: COLORS.WHITE
    },

    /*** Playlist Sidebar Styles ***/
    sidebar: {
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: COLORS.GRAY,
        height: "calc(100% - 90px)",
        borderRight: `1px solid ${COLORS.BLACK}`
    },

    sidebarOpenButton: {
        top: 35,
        width: 75,
        height: 50,
        borderRadius: 0,
        color: COLORS.GRAY,
        left: "calc(-50% + 37.5px)",
        backgroundColor: COLORS.ORANGE
    },

    sidebarHomeCont: {
        height: 100,
        padding: 20,
        width: "calc(100% - 40px)",
        borderBottom: `1px solid ${COLORS.BLACK}`
    },

    sidebarBox: {
        width: 50,
        height: 50,
        fontSize: 16,
        borderRadius: 0,
        color: COLORS.BLACK,
        margin: "0 10px 0 0",
        border: `1px solid ${COLORS.BLACK}`
    },

    sidebarButton: {
        margin: 0,
        fontSize: 16,
        borderRadius: 0,
        color: COLORS.BLACK,
        textTransform: "none",
        justifyContent: "start",
        padding: "10px 20px 10px 20px", 
        backgroundColor: COLORS.GRAY
    },
    
    sidebarHomeButton: {
        padding:0,
        width: 40,
        height: 40,
    },

    /*** Add Playlist Modal Styles ***/
    addPlaylistModal: {
        width: 350,
        top: "50%",
        height: 400,
        left: "50%",
        backgroundColor: COLORS.WHITE,
        padding: "40px 10px 20px 10px",
        transform: "translate(-50%, -50%)"
    },

    modalImage: {
        width: 175, 
        height: 175,
        margin: "0 0 10px 0",
        backgroundColor: COLORS.LIGHT_GRAY
    },

    /*** Zone Page Body Styles ***/
    zoneBody: (width: number, show: boolean, orientation: string) => ({
        height: `calc(100% - ${resize(width, orientation)? 91 : 130}px)`,
        backgroundColor: COLORS.GRAY,
        margin: `0 0 0 ${resize(width, orientation)? 225 : 0}px`,
        width: `calc(100% - ${resize(width, orientation)? 225 : 0}px)`,
        
    }),

    zoneSectionButton: {
        padding: "0",
        margin: "15px 10px 15px 10px",
        width: 150,
        height: 150,
        borderRadius: "50%",
    },

    /*** Schedule Styles ***/
    bucket: {
        height: 400,
        backgroundColor: COLORS.BLACK,
        borderTop: `1px solid ${COLORS.BLACK}`,
        borderBottom: `1px solid ${COLORS.BLACK}`,
    },

    scheduleSlider: {
        width: 920,
        '& .MuiSlider-thumb::before': {
            boxShadow: "none",
            color: COLORS.TRANSPARENT
        },
        '& .MuiSlider-thumb::after': {
            boxShadow: "none",
            color: COLORS.TRANSPARENT
        },
        '& .MuiSlider-active': {
            boxShadow: "none",
            color: COLORS.TRANSPARENT
        },
        '&::before': {
            boxShadow: "none",
            color: COLORS.TRANSPARENT
        },
        '& .MuiSlider-thumb': {
            width: 6,
            top: -184,
            height: 402,
            marginLeft: 0,
            borderRadius: 0,
            color: COLORS.GRAY,
            borderLeft: `1px solid ${COLORS.BLACK}`,
            borderRight: `1px solid ${COLORS.BLACK}`

        },
        margin: "-17px 0 0 0",
        color: COLORS.TRANSPARENT,
    },

    scheduleInput: {
        height: 50,
        width: 150,
        border: "none", 
        borderRadius: 0,
        backgroundColor: COLORS.TRANSPARENT, 
    },

    /*** Table Styles ***/
    tableHeader: {
        height: 125,
        width: "100%",
    },

    adminHeaderStyle: {
        height: 50, 
        width: "80%",
        marginBottom: 10,
        border: `1px solid ${COLORS.LIGHT_GRAY}`, 
    },

    playlistTable: (width?: number, orientation?: string) => ({
        marginTop: 0,
        scrollbarWidth: "none",
        width: "100%",
        maxHeight: `100%`, 
    }),

    adminTable: (width?: number, orientation?: string) => ({
        marginTop: 0,
        width: "80%",
        scrollbarWidth: "none",
        height: 'calc(100% - 150px - 30px)',
    }),

    adminSelect: {
        fontSize: 12,
        width: 100,
        borderRadius: 0,
        height: 40
    },

    trackTableRow: {
        padding: "10px", 
        width: "calc(100% - 20px)"
    },

    playlistPlayPauseButton: {
        width: 50, 
        height: 50, 
        color: COLORS.GRAY, 
        margin: "0 20px 0 0"
    },

    /*** Nav Styles ***/
    navProfileButton: {
        top: 20,
        width: 50,
        height: 50,
        overflow: "hidden",
        // left: "calc(50% - 50px)"
    },

    /*** Vibes Styles ***/
    vibeControls: (type: string, selected: string) => ({
        width: 150, 
        margin: 5,
        color: type !== selected? COLORS.BLACK : COLORS.GRAY,
        border: type !== selected? `1px solid ${COLORS.BLACK}` : "",
        backgroundColor: type !== selected? COLORS.GRAY : COLORS.BLACK
    }),

    ariaVibesControls: (type: string, selected: string) => ({
        width: 150,
        height: 40,
        color: type !== selected? COLORS.BLACK : COLORS.GRAY,
        border: type !== selected? `1px solid ${COLORS.BLACK}` : "",
        backgroundColor: type !== selected? COLORS.GRAY : COLORS.BLACK
    }),

    /*** Custom Table ***/
    customTableCell: {
        padding: 0,
        border: "none"
    },

    explicitTrack: {
        borderRadius: 5,
        margin: "0 2px 0 2px",
        color: `${COLORS.ORANGE}30`,
        padding: "3px 5px 0px 5px",
        border: `1px solid ${COLORS.ORANGE}30`,
    },

    notAvailableTrack: {
        borderRadius: 5,
        margin: "0 2px 0 2px",
        color: `${COLORS.BLACK}30`,
        padding: "3px 5px 0px 5px",
        border: `1px solid ${COLORS.BLACK}30`,
    }
}
